
body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


/********** Template CSS **********/
:root {
    --primary: #ed0000;
    --light: #F0FBFC;
    --dark: #181d38;
}

.fw-medium {
    font-weight: 600 !important;
}

.fw-semi-bold {
    font-weight: 700 !important;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 15px;
    bottom: 15px;
    z-index: 99;
}


/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}


/*** Button ***/
.btn {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    transition: .5s;
}

.btn.btn-primary,
.btn.btn-secondary {
    color: #FFFFFF;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 0px;
}


/*** Navbar ***/
.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}

.navbar-light .navbar-nav .nav-link a {
    margin-right: 30px;
    padding: 25px 0;
    color: #FFFFFF;
    font-size: 15px;
    text-transform: uppercase;
    outline: none;
}

.navbar-light .navbar-nav .nav_text_link:hover a,
.navbar-light .navbar-nav .nav_text_link.active a{
    color: var(--primary) !important;
}

@media (max-width: 991.98px) {
    .navbar-light .navbar-nav .nav-link  {
        margin-right: 0;
        padding: 10px 0;
    }

    .navbar-light .navbar-nav {
        border-top: 1px solid #EEEEEE;
    }
}

.nav_text_link a{
    color:  unset !important;
}

.navbar-light .navbar-brand,
.navbar-light a.btn {
    height: 75px;
}

.navbar-light .navbar-nav .nav-link {
    /* color: var(--dark) !important; */
    font-weight: 600 !important;
}
.nav_text_link .active{
    color: #ed0000 !important;
}

.navbar-light.sticky-top {
    top: -100px;
    transition: .5s;
}

@media (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        margin-top: 0;
        opacity: 0;
        visibility: hidden;
        transition: .5s;
    }

    .navbar .dropdown-menu.fade-down {
        top: 100%;
        transform: rotateX(-75deg);
        transform-origin: 0% 0%;
    }

    .navbar .nav-item:hover .dropdown-menu {
        top: 100%;
        transform: rotateX(0deg);
        visibility: visible;
        transition: .5s;
        opacity: 1;
    }
}



/*** Header carousel ***/


.cont_slider{
    margin-left: 22rem !important
  }
  
  .slider-container {
    width: 100%;
    position: relative;
  }
  
  .slider-image {
    width: 100%;
    height: auto;
  }
  
  .slick-prevv{
    position: absolute !important;
    top: 52%;
    z-index: 99;
    padding: 10px;
    cursor: pointer;
    transform: translate(-50%, -50%);
    margin: 7px 0;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background: transparent;
    border: 1px solid #FFFFFF;
    font-size: 25px;
    transition: .5s;
  
  }
  
  .slick-nextt{
    position: absolute;
    top: 60%;
    z-index: 99;
    transform: translate(-50%, -50%);
    margin: 7px 0;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background: transparent;
    border: 1px solid #FFFFFF;
    font-size: 25px;
    transition: .5s;
  
  }
  
  .slick-prevv{
    right: 10%;
  }
  
  .slick-nextt {
    right: 10%;
  }
  
  .slick-prevv:hover,
  .slick-nextt:hover {
    background: var(--primary);
    border-color: var(--primary);
  }


  .bg_img{
    background-repeat: no-repeat;
    height: 100dvh;
    background-size: cover;
  }
  
  .layer_slider{
    background-color: rgba(24, 29, 56, .7);
    height: 100%;
    width: 100%;
  }
  
  .slider_ms{
    margin-left: 15% !important;
  }
  .btn_slider{
    height: 60px;
    width: 200px;
  }
  @media only screen and (max-width: 992px) {
    .slider_ms{
      margin-left: 7% !important;
      margin-right: 7% !important;
    }
    .bg_img{
      height: 600px;
    }
    .slick-prevv{
      top: 93%;
      left: 45%;
      transform: translate(-50%, -50%);
    }
    .slick-nextt{
      top: 93%;
      left: 55%;
      transform: translate(-50%, -50%);
    }
  }
  
  @media only screen and (max-width: 600px) {
    .slider_ms{
      margin-left: 4% !important;
      margin-right: 4% !important;
    }
    .bg_img{
      height: 90vh;
    }
    .btn_slider{
      height: 50px;
      width: 150px;
    }
    .slick-prevv{
      left: 40%;
      width: 37px;
      height: 37px;
      font-size:18px;
    }
    .slick-nextt{
      width: 37px;
      height: 37px;
      left: 50%;
      font-size:18px;
    }
  }
  
  @media only screen and (max-width: 400px) {
    .slick-prevv{
      left: 36%;
    }
    .slick-nextt{
      left: 62%;
    }
    .btn_slider{
      height: 39px;
      width: 126px;
    }
  }
  
  .avtar_icon{
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  
  .testtestimonial_itmes{
    max-width: 500px;
  }
  
  .slick-dots li button{ 
    height: 15px;
    width: 15px;
    border:  1px solid #CCCCCC!important;
    background-color: white;
  }
  .slick-dots .slick-active  button{
    background-color: #ed0000 !important;
    border-color:#ed0000 !important;
  }
  
  .testimonial-item{
    padding-left: 10px!important;
    padding-right: 10px!important;
  }
  
  .slick-dots li button:before{
    font-family: unset !important;
  }





/* @media (max-width: 768px) {
    .header-carousel .owl-carousel-item {
        position: relative;
        min-height: 500px;
    }
    
    .header-carousel .owl-carousel-item img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.header-carousel .owl-nav {
    position: absolute;
    top: 50%;
    right: 8%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
}

.header-carousel .owl-nav .owl-prev,
.header-carousel .owl-nav .owl-next {
    margin: 7px 0;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background: transparent;
    border: 1px solid #FFFFFF;
    font-size: 22px;
    transition: .5s;
}

.header-carousel .owl-nav .owl-prev:hover,
.header-carousel .owl-nav .owl-next:hover {
    background: var(--primary);
    border-color: var(--primary);
}

.page-header {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.page-header-inner {
    background: rgba(15, 23, 43, .7);
}

.breadcrumb-item + .breadcrumb-item::before {
    color: var(--light);
} */

.page-header {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

/*** Section Title ***/
.section-title {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
}

.section-title::before {
    position: absolute;
    content: "";
    width: calc(100% + 50px);
    height: 2px;
    top: 4px;
    left: -25px;
    background: var(--primary);
    z-index: -1;
}

.section-title::after {
    position: absolute;
    content: "";
    width: calc(100% + 90px);
    height: 2px;
    bottom: 5px;
    left: -45px;
    background: var(--primary);
    z-index: -1;
}

.section-title.text-start::before {
    width: calc(100% + 40px);
    left: 0;
}

.section-title.text-start::after {
    width: calc(100% + 60px);
    left: 0;
}


/*** Service ***/
.service-item {
    background: var(--light);
    transition: .5s;
}

.service-item:hover {
    margin-top: -10px;
    background: var(--primary);
}

.service-item * {
    transition: .5s;
}

.service-item:hover * {
    color: var(--light) !important;
}


/*** Categories & Courses ***/
.category img,
.course-item img {
    transition: .5s;
}

.category a:hover img,
.course-item:hover img {
    transform: scale(1.1);
}


/*** Team ***/
.team-item img {
    transition: .5s;
}

.team-item:hover img {
    transform: scale(1.1);
}


/*** Testimonial ***/
.testimonial-carousel::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 1;
}

.testimonial-carousel::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    height: 100%;
    width: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 1;
}

@media (min-width: 768px) {
    .testimonial-carousel::before,
    .testimonial-carousel::after {
        width: 200px;
    }
}

@media (min-width: 992px) {
    .testimonial-carousel::before,
    .testimonial-carousel::after {
        width: 300px;
    }
}

.testimonial-carousel .owl-item .testimonial-text,
.testimonial-carousel .owl-item.center .testimonial-text * {
    transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-text {
    background: var(--primary) !important;
}

.testimonial-carousel .owl-item.center .testimonial-text * {
    color: #FFFFFF !important;
}

.testimonial-carousel .owl-dots {
    margin-top: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.testimonial-carousel .owl-dot {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    width: 15px;
    height: 15px;
    border: 1px solid #CCCCCC;
    transition: .5s;
}

.testimonial-carousel .owl-dot.active {
    background: var(--primary);
    border-color: var(--primary);
}


/*** Footer ***/
.footer .btn.btn-social {
    margin-right: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light);
    font-weight: normal;
    border: 1px solid #FFFFFF;
    border-radius: 35px;
    transition: .3s;
}

.footer .btn.btn-social:hover {
    color: var(--primary);
}

.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    letter-spacing: 1px;
    box-shadow: none;
}

.footer .copyright {
    padding: 25px 0;
    font-size: 15px;
    border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
    color: var(--light);
}

.footer .footer-menu a {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid rgba(255, 255, 255, .1);
}

.footer .footer-menu a:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
}

.error_page_btn{
    border-radius: 50px;
    font-weight: 500 !important;
    width: 240px;
    height: 62px;
}


@media (max-width: 768px) {
    .error_page_btn{
        width: 205px;
        height: 55px;
    }
}

.back_layer{
    background: linear-gradient(rgba(24, 29, 56, .7), rgba(24, 29, 56, .7)) ;  
}

.Services_img_responsive{
    height: 300px;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.list-styled-circle {
    list-style: circle;
    font-size: 22px;
    color: #ed0000;
}

.list-styled-circle:hover {
    list-style: disc;
    font-size: 22px;
    color: #ed0000;
}

.list-styled-circle span{
    font-size: 16px;
    color: #6C757D;
    font-weight: 500;
}

.content_pr {
    line-height: 25px;
    word-spacing: 1.5px;
    font-size: 17px;
    font-weight: lighter;
}

.service_itm p{
    font-size: 18px;
    font-weight: lighter;
    line-height: 25px;
    word-spacing: 2px;
}

.service_itm h2{
    color: #ed0000;
    font-weight: 900;
    letter-spacing: 1.5px;
}

@media only screen and (max-width: 600px) {
    .remove_class_section_title .section-title::before{
        content: none;
    }
    .remove_class_section_title .section-title::after{
        content: none;
    }
    .dropdown-item{
        display:block;
        width:100%;
        padding:.25rem 1rem;
        clear:both;
        font-weight:400;
        color:#212529;
        text-align:inherit;
        white-space:initial;
        background-color:transparent;
        border:0
    }
  }

  .btn_hover:hover{
    background-color: #ed0000;
    /* border-color: #1fc2d1; */
    box-shadow: none;
    border-radius: 100%;
}
.btn_hover:focus{
    box-shadow: none;
}
#hv{
    color:#ed0000;
}
.btn_hover:hover #hv{
    color: white;
}

#basic-navbar-nav .dropdown-menu{
    left: -100% !important
}
#basic-navbar-nav .dropdown-menu a a{
    color: black !important;
}
#basic-navbar-nav .dropdown-menu a:hover a{
    color: var(--primary) !important;
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: unset !important;
}