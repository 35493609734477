.read_more_link {
    background-color: #f0fbfc;
    color: #ed0000;
    border-color: #ed0000;
    cursor: pointer;
  }

  .read_more_link:hover {     

    border-color: #f0fbfc;
  }