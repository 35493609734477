.fs-1px
{
	font-size: 1px;
}

.fs-2px
{
	font-size: 2px;
}

.fs-3px
{
	font-size: 3px;
}

.fs-4px
{
	font-size: 4px;
}

.fs-5px
{
	font-size: 5px;
}

.fs-6px
{
	font-size: 6px;
}

.fs-7px
{
	font-size: 7px;
}

.fs-8px
{
	font-size: 8px;
}

.fs-9px
{
	font-size: 9px;
}

.fs-10px
{
	font-size: 10px;
}

.fs-11px
{
	font-size: 11px;
}

.fs-12px
{
	font-size: 12px;
}

.fs-13px
{
	font-size: 13px;
}

.fs-14px
{
	font-size: 14px;
}

.fs-15px
{
	font-size: 15px;
}

.fs-16px
{
	font-size: 16px;
}

.fs-17px
{
	font-size: 17px;
}

.fs-18px
{
	font-size: 18px;
}

.fs-19px
{
	font-size: 19px;
}

.fs-20px
{
	font-size: 20px;
}

.fs-21px
{
	font-size: 21px;
}

.fs-22px
{
	font-size: 22px;
}

.fs-23px
{
	font-size: 23px;
}

.fs-24px
{
	font-size: 24px;
}

.fs-25px
{
	font-size: 25px;
}

.fs-26px
{
	font-size: 26px;
}

.fs-27px
{
	font-size: 27px;
}

.fs-28px
{
	font-size: 28px;
}

.fs-29px
{
	font-size: 29px;
}

.fs-30px
{
	font-size: 30px;
}

.fs-31px
{
	font-size: 31px;
}

.fs-32px
{
	font-size: 32px;
}

.fs-33px
{
	font-size: 33px;
}

.fs-34px
{
	font-size: 34px;
}

.fs-35px
{
	font-size: 35px;
}

.fs-36px
{
	font-size: 36px;
}

.fs-37px
{
	font-size: 37px;
}

.fs-38px
{
	font-size: 38px;
}

.fs-39px
{
	font-size: 39px;
}

.fs-40px
{
	font-size: 40px;
}

.fs-41px
{
	font-size: 41px;
}

.fs-42px
{
	font-size: 42px;
}

.fs-43px
{
	font-size: 43px;
}

.fs-44px
{
	font-size: 44px;
}

.fs-45px
{
	font-size: 45px;
}

.fs-46px
{
	font-size: 46px;
}

.fs-47px
{
	font-size: 47px;
}

.fs-48px
{
	font-size: 48px;
}

.fs-49px
{
	font-size: 49px;
}

.fs-50px
{
	font-size: 50px;
}

.fs-51px
{
	font-size: 51px;
}

.fs-52px
{
	font-size: 52px;
}

.fs-53px
{
	font-size: 53px;
}

.fs-54px
{
	font-size: 54px;
}

.fs-55px
{
	font-size: 55px;
}

.fs-56px
{
	font-size: 56px;
}

.fs-57px
{
	font-size: 57px;
}

.fs-58px
{
	font-size: 58px;
}

.fs-59px
{
	font-size: 59px;
}

.fs-60px
{
	font-size: 60px;
}

.fs-61px
{
	font-size: 61px;
}

.fs-62px
{
	font-size: 62px;
}

.fs-63px
{
	font-size: 63px;
}

.fs-64px
{
	font-size: 64px;
}

.fs-65px
{
	font-size: 65px;
}

.fs-66px
{
	font-size: 66px;
}

.fs-67px
{
	font-size: 67px;
}

.fs-68px
{
	font-size: 68px;
}

.fs-69px
{
	font-size: 69px;
}

.fs-70px
{
	font-size: 70px;
}

.fs-71px
{
	font-size: 71px;
}

.fs-72px
{
	font-size: 72px;
}

.fs-73px
{
	font-size: 73px;
}

.fs-74px
{
	font-size: 74px;
}

.fs-75px
{
	font-size: 75px;
}

.fs-76px
{
	font-size: 76px;
}

.fs-77px
{
	font-size: 77px;
}

.fs-78px
{
	font-size: 78px;
}

.fs-79px
{
	font-size: 79px;
}

.fs-80px
{
	font-size: 80px;
}

.fs-81px
{
	font-size: 81px;
}

.fs-82px
{
	font-size: 82px;
}

.fs-83px
{
	font-size: 83px;
}

.fs-84px
{
	font-size: 84px;
}

.fs-85px
{
	font-size: 85px;
}

.fs-86px
{
	font-size: 86px;
}

.fs-87px
{
	font-size: 87px;
}

.fs-88px
{
	font-size: 88px;
}

.fs-89px
{
	font-size: 89px;
}

.fs-90px
{
	font-size: 90px;
}

.fs-91px
{
	font-size: 91px;
}

.fs-92px
{
	font-size: 92px;
}

.fs-93px
{
	font-size: 93px;
}

.fs-94px
{
	font-size: 94px;
}

.fs-95px
{
	font-size: 95px;
}

.fs-96px
{
	font-size: 96px;
}

.fs-97px
{
	font-size: 97px;
}

.fs-98px
{
	font-size: 98px;
}

.fs-99px
{
	font-size: 99px;
}

.fs-100px
{
	font-size: 100px;
}

.fs-101px
{
	font-size: 101px;
}

.fs-102px
{
	font-size: 102px;
}

.fs-103px
{
	font-size: 103px;
}

.fs-104px
{
	font-size: 104px;
}

.fs-105px
{
	font-size: 105px;
}

.fs-106px
{
	font-size: 106px;
}

.fs-107px
{
	font-size: 107px;
}

.fs-108px
{
	font-size: 108px;
}

.fs-109px
{
	font-size: 109px;
}

.fs-110px
{
	font-size: 110px;
}

.fs-111px
{
	font-size: 111px;
}

.fs-112px
{
	font-size: 112px;
}

.fs-113px
{
	font-size: 113px;
}

.fs-114px
{
	font-size: 114px;
}

.fs-115px
{
	font-size: 115px;
}

.fs-116px
{
	font-size: 116px;
}

.fs-117px
{
	font-size: 117px;
}

.fs-118px
{
	font-size: 118px;
}

.fs-119px
{
	font-size: 119px;
}

.fs-120px
{
	font-size: 120px;
}

.fs-121px
{
	font-size: 121px;
}

.fs-122px
{
	font-size: 122px;
}

.fs-123px
{
	font-size: 123px;
}

.fs-124px
{
	font-size: 124px;
}

.fs-125px
{
	font-size: 125px;
}

.fs-126px
{
	font-size: 126px;
}

.fs-127px
{
	font-size: 127px;
}

.fs-128px
{
	font-size: 128px;
}

.fs-129px
{
	font-size: 129px;
}

.fs-130px
{
	font-size: 130px;
}

.fs-131px
{
	font-size: 131px;
}

.fs-132px
{
	font-size: 132px;
}

.fs-133px
{
	font-size: 133px;
}

.fs-134px
{
	font-size: 134px;
}

.fs-135px
{
	font-size: 135px;
}

.fs-136px
{
	font-size: 136px;
}

.fs-137px
{
	font-size: 137px;
}

.fs-138px
{
	font-size: 138px;
}

.fs-139px
{
	font-size: 139px;
}

.fs-140px
{
	font-size: 140px;
}

.fs-141px
{
	font-size: 141px;
}

.fs-142px
{
	font-size: 142px;
}

.fs-143px
{
	font-size: 143px;
}

.fs-144px
{
	font-size: 144px;
}

.fs-145px
{
	font-size: 145px;
}

.fs-146px
{
	font-size: 146px;
}

.fs-147px
{
	font-size: 147px;
}

.fs-148px
{
	font-size: 148px;
}

.fs-149px
{
	font-size: 149px;
}

.fs-150px
{
	font-size: 150px;
}

.fs-151px
{
	font-size: 151px;
}

.fs-152px
{
	font-size: 152px;
}

.fs-153px
{
	font-size: 153px;
}

.fs-154px
{
	font-size: 154px;
}

.fs-155px
{
	font-size: 155px;
}

.fs-156px
{
	font-size: 156px;
}

.fs-157px
{
	font-size: 157px;
}

.fs-158px
{
	font-size: 158px;
}

.fs-159px
{
	font-size: 159px;
}

.fs-160px
{
	font-size: 160px;
}

.fs-161px
{
	font-size: 161px;
}

.fs-162px
{
	font-size: 162px;
}

.fs-163px
{
	font-size: 163px;
}

.fs-164px
{
	font-size: 164px;
}

.fs-165px
{
	font-size: 165px;
}

.fs-166px
{
	font-size: 166px;
}

.fs-167px
{
	font-size: 167px;
}

.fs-168px
{
	font-size: 168px;
}

.fs-169px
{
	font-size: 169px;
}

.fs-170px
{
	font-size: 170px;
}

.fs-171px
{
	font-size: 171px;
}

.fs-172px
{
	font-size: 172px;
}

.fs-173px
{
	font-size: 173px;
}

.fs-174px
{
	font-size: 174px;
}

.fs-175px
{
	font-size: 175px;
}

.fs-176px
{
	font-size: 176px;
}

.fs-177px
{
	font-size: 177px;
}

.fs-178px
{
	font-size: 178px;
}

.fs-179px
{
	font-size: 179px;
}

.fs-180px
{
	font-size: 180px;
}

.fs-181px
{
	font-size: 181px;
}

.fs-182px
{
	font-size: 182px;
}

.fs-183px
{
	font-size: 183px;
}

.fs-184px
{
	font-size: 184px;
}

.fs-185px
{
	font-size: 185px;
}

.fs-186px
{
	font-size: 186px;
}

.fs-187px
{
	font-size: 187px;
}

.fs-188px
{
	font-size: 188px;
}

.fs-189px
{
	font-size: 189px;
}

.fs-190px
{
	font-size: 190px;
}

.fs-191px
{
	font-size: 191px;
}

.fs-192px
{
	font-size: 192px;
}

.fs-193px
{
	font-size: 193px;
}

.fs-194px
{
	font-size: 194px;
}

.fs-195px
{
	font-size: 195px;
}

.fs-196px
{
	font-size: 196px;
}

.fs-197px
{
	font-size: 197px;
}

.fs-198px
{
	font-size: 198px;
}

.fs-199px
{
	font-size: 199px;
}

.fs-200px
{
	font-size: 200px;
}

.fs-201px
{
	font-size: 201px;
}

.fs-202px
{
	font-size: 202px;
}

.fs-203px
{
	font-size: 203px;
}

.fs-204px
{
	font-size: 204px;
}

.fs-205px
{
	font-size: 205px;
}

.fs-206px
{
	font-size: 206px;
}

.fs-207px
{
	font-size: 207px;
}

.fs-208px
{
	font-size: 208px;
}

.fs-209px
{
	font-size: 209px;
}

.fs-210px
{
	font-size: 210px;
}

.fs-211px
{
	font-size: 211px;
}

.fs-212px
{
	font-size: 212px;
}

.fs-213px
{
	font-size: 213px;
}

.fs-214px
{
	font-size: 214px;
}

.fs-215px
{
	font-size: 215px;
}

.fs-216px
{
	font-size: 216px;
}

.fs-217px
{
	font-size: 217px;
}

.fs-218px
{
	font-size: 218px;
}

.fs-219px
{
	font-size: 219px;
}

.fs-220px
{
	font-size: 220px;
}

.fs-221px
{
	font-size: 221px;
}

.fs-222px
{
	font-size: 222px;
}

.fs-223px
{
	font-size: 223px;
}

.fs-224px
{
	font-size: 224px;
}

.fs-225px
{
	font-size: 225px;
}

.fs-226px
{
	font-size: 226px;
}

.fs-227px
{
	font-size: 227px;
}

.fs-228px
{
	font-size: 228px;
}

.fs-229px
{
	font-size: 229px;
}

.fs-230px
{
	font-size: 230px;
}

.fs-231px
{
	font-size: 231px;
}

.fs-232px
{
	font-size: 232px;
}

.fs-233px
{
	font-size: 233px;
}

.fs-234px
{
	font-size: 234px;
}

.fs-235px
{
	font-size: 235px;
}

.fs-236px
{
	font-size: 236px;
}

.fs-237px
{
	font-size: 237px;
}

.fs-238px
{
	font-size: 238px;
}

.fs-239px
{
	font-size: 239px;
}

.fs-240px
{
	font-size: 240px;
}

.fs-241px
{
	font-size: 241px;
}

.fs-242px
{
	font-size: 242px;
}

.fs-243px
{
	font-size: 243px;
}

.fs-244px
{
	font-size: 244px;
}

.fs-245px
{
	font-size: 245px;
}

.fs-246px
{
	font-size: 246px;
}

.fs-247px
{
	font-size: 247px;
}

.fs-248px
{
	font-size: 248px;
}

.fs-249px
{
	font-size: 249px;
}

.fs-250px
{
	font-size: 250px;
}

.fs-251px
{
	font-size: 251px;
}

.fs-252px
{
	font-size: 252px;
}

.fs-253px
{
	font-size: 253px;
}

.fs-254px
{
	font-size: 254px;
}

.fs-255px
{
	font-size: 255px;
}

.fs-256px
{
	font-size: 256px;
}

@media (min-width: 576px)
{	
	.fs-sm-1px
	{
		font-size: 1px;
	}

	.fs-sm-2px
	{
		font-size: 2px;
	}

	.fs-sm-3px
	{
		font-size: 3px;
	}

	.fs-sm-4px
	{
		font-size: 4px;
	}

	.fs-sm-5px
	{
		font-size: 5px;
	}

	.fs-sm-6px
	{
		font-size: 6px;
	}

	.fs-sm-7px
	{
		font-size: 7px;
	}

	.fs-sm-8px
	{
		font-size: 8px;
	}

	.fs-sm-9px
	{
		font-size: 9px;
	}

	.fs-sm-10px
	{
		font-size: 10px;
	}

	.fs-sm-11px
	{
		font-size: 11px;
	}

	.fs-sm-12px
	{
		font-size: 12px;
	}

	.fs-sm-13px
	{
		font-size: 13px;
	}

	.fs-sm-14px
	{
		font-size: 14px;
	}

	.fs-sm-15px
	{
		font-size: 15px;
	}

	.fs-sm-16px
	{
		font-size: 16px;
	}

	.fs-sm-17px
	{
		font-size: 17px;
	}

	.fs-sm-18px
	{
		font-size: 18px;
	}

	.fs-sm-19px
	{
		font-size: 19px;
	}

	.fs-sm-20px
	{
		font-size: 20px;
	}

	.fs-sm-21px
	{
		font-size: 21px;
	}

	.fs-sm-22px
	{
		font-size: 22px;
	}

	.fs-sm-23px
	{
		font-size: 23px;
	}

	.fs-sm-24px
	{
		font-size: 24px;
	}

	.fs-sm-25px
	{
		font-size: 25px;
	}

	.fs-sm-26px
	{
		font-size: 26px;
	}

	.fs-sm-27px
	{
		font-size: 27px;
	}

	.fs-sm-28px
	{
		font-size: 28px;
	}

	.fs-sm-29px
	{
		font-size: 29px;
	}

	.fs-sm-30px
	{
		font-size: 30px;
	}

	.fs-sm-31px
	{
		font-size: 31px;
	}

	.fs-sm-32px
	{
		font-size: 32px;
	}

	.fs-sm-33px
	{
		font-size: 33px;
	}

	.fs-sm-34px
	{
		font-size: 34px;
	}

	.fs-sm-35px
	{
		font-size: 35px;
	}

	.fs-sm-36px
	{
		font-size: 36px;
	}

	.fs-sm-37px
	{
		font-size: 37px;
	}

	.fs-sm-38px
	{
		font-size: 38px;
	}

	.fs-sm-39px
	{
		font-size: 39px;
	}

	.fs-sm-40px
	{
		font-size: 40px;
	}

	.fs-sm-41px
	{
		font-size: 41px;
	}

	.fs-sm-42px
	{
		font-size: 42px;
	}

	.fs-sm-43px
	{
		font-size: 43px;
	}

	.fs-sm-44px
	{
		font-size: 44px;
	}

	.fs-sm-45px
	{
		font-size: 45px;
	}

	.fs-sm-46px
	{
		font-size: 46px;
	}

	.fs-sm-47px
	{
		font-size: 47px;
	}

	.fs-sm-48px
	{
		font-size: 48px;
	}

	.fs-sm-49px
	{
		font-size: 49px;
	}

	.fs-sm-50px
	{
		font-size: 50px;
	}

	.fs-sm-51px
	{
		font-size: 51px;
	}

	.fs-sm-52px
	{
		font-size: 52px;
	}

	.fs-sm-53px
	{
		font-size: 53px;
	}

	.fs-sm-54px
	{
		font-size: 54px;
	}

	.fs-sm-55px
	{
		font-size: 55px;
	}

	.fs-sm-56px
	{
		font-size: 56px;
	}

	.fs-sm-57px
	{
		font-size: 57px;
	}

	.fs-sm-58px
	{
		font-size: 58px;
	}

	.fs-sm-59px
	{
		font-size: 59px;
	}

	.fs-sm-60px
	{
		font-size: 60px;
	}

	.fs-sm-61px
	{
		font-size: 61px;
	}

	.fs-sm-62px
	{
		font-size: 62px;
	}

	.fs-sm-63px
	{
		font-size: 63px;
	}

	.fs-sm-64px
	{
		font-size: 64px;
	}

	.fs-sm-65px
	{
		font-size: 65px;
	}

	.fs-sm-66px
	{
		font-size: 66px;
	}

	.fs-sm-67px
	{
		font-size: 67px;
	}

	.fs-sm-68px
	{
		font-size: 68px;
	}

	.fs-sm-69px
	{
		font-size: 69px;
	}

	.fs-sm-70px
	{
		font-size: 70px;
	}

	.fs-sm-71px
	{
		font-size: 71px;
	}

	.fs-sm-72px
	{
		font-size: 72px;
	}

	.fs-sm-73px
	{
		font-size: 73px;
	}

	.fs-sm-74px
	{
		font-size: 74px;
	}

	.fs-sm-75px
	{
		font-size: 75px;
	}

	.fs-sm-76px
	{
		font-size: 76px;
	}

	.fs-sm-77px
	{
		font-size: 77px;
	}

	.fs-sm-78px
	{
		font-size: 78px;
	}

	.fs-sm-79px
	{
		font-size: 79px;
	}

	.fs-sm-80px
	{
		font-size: 80px;
	}

	.fs-sm-81px
	{
		font-size: 81px;
	}

	.fs-sm-82px
	{
		font-size: 82px;
	}

	.fs-sm-83px
	{
		font-size: 83px;
	}

	.fs-sm-84px
	{
		font-size: 84px;
	}

	.fs-sm-85px
	{
		font-size: 85px;
	}

	.fs-sm-86px
	{
		font-size: 86px;
	}

	.fs-sm-87px
	{
		font-size: 87px;
	}

	.fs-sm-88px
	{
		font-size: 88px;
	}

	.fs-sm-89px
	{
		font-size: 89px;
	}

	.fs-sm-90px
	{
		font-size: 90px;
	}

	.fs-sm-91px
	{
		font-size: 91px;
	}

	.fs-sm-92px
	{
		font-size: 92px;
	}

	.fs-sm-93px
	{
		font-size: 93px;
	}

	.fs-sm-94px
	{
		font-size: 94px;
	}

	.fs-sm-95px
	{
		font-size: 95px;
	}

	.fs-sm-96px
	{
		font-size: 96px;
	}

	.fs-sm-97px
	{
		font-size: 97px;
	}

	.fs-sm-98px
	{
		font-size: 98px;
	}

	.fs-sm-99px
	{
		font-size: 99px;
	}

	.fs-sm-100px
	{
		font-size: 100px;
	}

	.fs-sm-101px
	{
		font-size: 101px;
	}

	.fs-sm-102px
	{
		font-size: 102px;
	}

	.fs-sm-103px
	{
		font-size: 103px;
	}

	.fs-sm-104px
	{
		font-size: 104px;
	}

	.fs-sm-105px
	{
		font-size: 105px;
	}

	.fs-sm-106px
	{
		font-size: 106px;
	}

	.fs-sm-107px
	{
		font-size: 107px;
	}

	.fs-sm-108px
	{
		font-size: 108px;
	}

	.fs-sm-109px
	{
		font-size: 109px;
	}

	.fs-sm-110px
	{
		font-size: 110px;
	}

	.fs-sm-111px
	{
		font-size: 111px;
	}

	.fs-sm-112px
	{
		font-size: 112px;
	}

	.fs-sm-113px
	{
		font-size: 113px;
	}

	.fs-sm-114px
	{
		font-size: 114px;
	}

	.fs-sm-115px
	{
		font-size: 115px;
	}

	.fs-sm-116px
	{
		font-size: 116px;
	}

	.fs-sm-117px
	{
		font-size: 117px;
	}

	.fs-sm-118px
	{
		font-size: 118px;
	}

	.fs-sm-119px
	{
		font-size: 119px;
	}

	.fs-sm-120px
	{
		font-size: 120px;
	}

	.fs-sm-121px
	{
		font-size: 121px;
	}

	.fs-sm-122px
	{
		font-size: 122px;
	}

	.fs-sm-123px
	{
		font-size: 123px;
	}

	.fs-sm-124px
	{
		font-size: 124px;
	}

	.fs-sm-125px
	{
		font-size: 125px;
	}

	.fs-sm-126px
	{
		font-size: 126px;
	}

	.fs-sm-127px
	{
		font-size: 127px;
	}

	.fs-sm-128px
	{
		font-size: 128px;
	}

	.fs-sm-129px
	{
		font-size: 129px;
	}

	.fs-sm-130px
	{
		font-size: 130px;
	}

	.fs-sm-131px
	{
		font-size: 131px;
	}

	.fs-sm-132px
	{
		font-size: 132px;
	}

	.fs-sm-133px
	{
		font-size: 133px;
	}

	.fs-sm-134px
	{
		font-size: 134px;
	}

	.fs-sm-135px
	{
		font-size: 135px;
	}

	.fs-sm-136px
	{
		font-size: 136px;
	}

	.fs-sm-137px
	{
		font-size: 137px;
	}

	.fs-sm-138px
	{
		font-size: 138px;
	}

	.fs-sm-139px
	{
		font-size: 139px;
	}

	.fs-sm-140px
	{
		font-size: 140px;
	}

	.fs-sm-141px
	{
		font-size: 141px;
	}

	.fs-sm-142px
	{
		font-size: 142px;
	}

	.fs-sm-143px
	{
		font-size: 143px;
	}

	.fs-sm-144px
	{
		font-size: 144px;
	}

	.fs-sm-145px
	{
		font-size: 145px;
	}

	.fs-sm-146px
	{
		font-size: 146px;
	}

	.fs-sm-147px
	{
		font-size: 147px;
	}

	.fs-sm-148px
	{
		font-size: 148px;
	}

	.fs-sm-149px
	{
		font-size: 149px;
	}

	.fs-sm-150px
	{
		font-size: 150px;
	}

	.fs-sm-151px
	{
		font-size: 151px;
	}

	.fs-sm-152px
	{
		font-size: 152px;
	}

	.fs-sm-153px
	{
		font-size: 153px;
	}

	.fs-sm-154px
	{
		font-size: 154px;
	}

	.fs-sm-155px
	{
		font-size: 155px;
	}

	.fs-sm-156px
	{
		font-size: 156px;
	}

	.fs-sm-157px
	{
		font-size: 157px;
	}

	.fs-sm-158px
	{
		font-size: 158px;
	}

	.fs-sm-159px
	{
		font-size: 159px;
	}

	.fs-sm-160px
	{
		font-size: 160px;
	}

	.fs-sm-161px
	{
		font-size: 161px;
	}

	.fs-sm-162px
	{
		font-size: 162px;
	}

	.fs-sm-163px
	{
		font-size: 163px;
	}

	.fs-sm-164px
	{
		font-size: 164px;
	}

	.fs-sm-165px
	{
		font-size: 165px;
	}

	.fs-sm-166px
	{
		font-size: 166px;
	}

	.fs-sm-167px
	{
		font-size: 167px;
	}

	.fs-sm-168px
	{
		font-size: 168px;
	}

	.fs-sm-169px
	{
		font-size: 169px;
	}

	.fs-sm-170px
	{
		font-size: 170px;
	}

	.fs-sm-171px
	{
		font-size: 171px;
	}

	.fs-sm-172px
	{
		font-size: 172px;
	}

	.fs-sm-173px
	{
		font-size: 173px;
	}

	.fs-sm-174px
	{
		font-size: 174px;
	}

	.fs-sm-175px
	{
		font-size: 175px;
	}

	.fs-sm-176px
	{
		font-size: 176px;
	}

	.fs-sm-177px
	{
		font-size: 177px;
	}

	.fs-sm-178px
	{
		font-size: 178px;
	}

	.fs-sm-179px
	{
		font-size: 179px;
	}

	.fs-sm-180px
	{
		font-size: 180px;
	}

	.fs-sm-181px
	{
		font-size: 181px;
	}

	.fs-sm-182px
	{
		font-size: 182px;
	}

	.fs-sm-183px
	{
		font-size: 183px;
	}

	.fs-sm-184px
	{
		font-size: 184px;
	}

	.fs-sm-185px
	{
		font-size: 185px;
	}

	.fs-sm-186px
	{
		font-size: 186px;
	}

	.fs-sm-187px
	{
		font-size: 187px;
	}

	.fs-sm-188px
	{
		font-size: 188px;
	}

	.fs-sm-189px
	{
		font-size: 189px;
	}

	.fs-sm-190px
	{
		font-size: 190px;
	}

	.fs-sm-191px
	{
		font-size: 191px;
	}

	.fs-sm-192px
	{
		font-size: 192px;
	}

	.fs-sm-193px
	{
		font-size: 193px;
	}

	.fs-sm-194px
	{
		font-size: 194px;
	}

	.fs-sm-195px
	{
		font-size: 195px;
	}

	.fs-sm-196px
	{
		font-size: 196px;
	}

	.fs-sm-197px
	{
		font-size: 197px;
	}

	.fs-sm-198px
	{
		font-size: 198px;
	}

	.fs-sm-199px
	{
		font-size: 199px;
	}

	.fs-sm-200px
	{
		font-size: 200px;
	}

	.fs-sm-201px
	{
		font-size: 201px;
	}

	.fs-sm-202px
	{
		font-size: 202px;
	}

	.fs-sm-203px
	{
		font-size: 203px;
	}

	.fs-sm-204px
	{
		font-size: 204px;
	}

	.fs-sm-205px
	{
		font-size: 205px;
	}

	.fs-sm-206px
	{
		font-size: 206px;
	}

	.fs-sm-207px
	{
		font-size: 207px;
	}

	.fs-sm-208px
	{
		font-size: 208px;
	}

	.fs-sm-209px
	{
		font-size: 209px;
	}

	.fs-sm-210px
	{
		font-size: 210px;
	}

	.fs-sm-211px
	{
		font-size: 211px;
	}

	.fs-sm-212px
	{
		font-size: 212px;
	}

	.fs-sm-213px
	{
		font-size: 213px;
	}

	.fs-sm-214px
	{
		font-size: 214px;
	}

	.fs-sm-215px
	{
		font-size: 215px;
	}

	.fs-sm-216px
	{
		font-size: 216px;
	}

	.fs-sm-217px
	{
		font-size: 217px;
	}

	.fs-sm-218px
	{
		font-size: 218px;
	}

	.fs-sm-219px
	{
		font-size: 219px;
	}

	.fs-sm-220px
	{
		font-size: 220px;
	}

	.fs-sm-221px
	{
		font-size: 221px;
	}

	.fs-sm-222px
	{
		font-size: 222px;
	}

	.fs-sm-223px
	{
		font-size: 223px;
	}

	.fs-sm-224px
	{
		font-size: 224px;
	}

	.fs-sm-225px
	{
		font-size: 225px;
	}

	.fs-sm-226px
	{
		font-size: 226px;
	}

	.fs-sm-227px
	{
		font-size: 227px;
	}

	.fs-sm-228px
	{
		font-size: 228px;
	}

	.fs-sm-229px
	{
		font-size: 229px;
	}

	.fs-sm-230px
	{
		font-size: 230px;
	}

	.fs-sm-231px
	{
		font-size: 231px;
	}

	.fs-sm-232px
	{
		font-size: 232px;
	}

	.fs-sm-233px
	{
		font-size: 233px;
	}

	.fs-sm-234px
	{
		font-size: 234px;
	}

	.fs-sm-235px
	{
		font-size: 235px;
	}

	.fs-sm-236px
	{
		font-size: 236px;
	}

	.fs-sm-237px
	{
		font-size: 237px;
	}

	.fs-sm-238px
	{
		font-size: 238px;
	}

	.fs-sm-239px
	{
		font-size: 239px;
	}

	.fs-sm-240px
	{
		font-size: 240px;
	}

	.fs-sm-241px
	{
		font-size: 241px;
	}

	.fs-sm-242px
	{
		font-size: 242px;
	}

	.fs-sm-243px
	{
		font-size: 243px;
	}

	.fs-sm-244px
	{
		font-size: 244px;
	}

	.fs-sm-245px
	{
		font-size: 245px;
	}

	.fs-sm-246px
	{
		font-size: 246px;
	}

	.fs-sm-247px
	{
		font-size: 247px;
	}

	.fs-sm-248px
	{
		font-size: 248px;
	}

	.fs-sm-249px
	{
		font-size: 249px;
	}

	.fs-sm-250px
	{
		font-size: 250px;
	}

	.fs-sm-251px
	{
		font-size: 251px;
	}

	.fs-sm-252px
	{
		font-size: 252px;
	}

	.fs-sm-253px
	{
		font-size: 253px;
	}

	.fs-sm-254px
	{
		font-size: 254px;
	}

	.fs-sm-255px
	{
		font-size: 255px;
	}

	.fs-sm-256px
	{
		font-size: 256px;
	}

}

@media (min-width: 768px)
{	
	.fs-md-1px
	{
		font-size: 1px;
	}

	.fs-md-2px
	{
		font-size: 2px;
	}

	.fs-md-3px
	{
		font-size: 3px;
	}

	.fs-md-4px
	{
		font-size: 4px;
	}

	.fs-md-5px
	{
		font-size: 5px;
	}

	.fs-md-6px
	{
		font-size: 6px;
	}

	.fs-md-7px
	{
		font-size: 7px;
	}

	.fs-md-8px
	{
		font-size: 8px;
	}

	.fs-md-9px
	{
		font-size: 9px;
	}

	.fs-md-10px
	{
		font-size: 10px;
	}

	.fs-md-11px
	{
		font-size: 11px;
	}

	.fs-md-12px
	{
		font-size: 12px;
	}

	.fs-md-13px
	{
		font-size: 13px;
	}

	.fs-md-14px
	{
		font-size: 14px;
	}

	.fs-md-15px
	{
		font-size: 15px;
	}

	.fs-md-16px
	{
		font-size: 16px;
	}

	.fs-md-17px
	{
		font-size: 17px;
	}

	.fs-md-18px
	{
		font-size: 18px;
	}

	.fs-md-19px
	{
		font-size: 19px;
	}

	.fs-md-20px
	{
		font-size: 20px;
	}

	.fs-md-21px
	{
		font-size: 21px;
	}

	.fs-md-22px
	{
		font-size: 22px;
	}

	.fs-md-23px
	{
		font-size: 23px;
	}

	.fs-md-24px
	{
		font-size: 24px;
	}

	.fs-md-25px
	{
		font-size: 25px;
	}

	.fs-md-26px
	{
		font-size: 26px;
	}

	.fs-md-27px
	{
		font-size: 27px;
	}

	.fs-md-28px
	{
		font-size: 28px;
	}

	.fs-md-29px
	{
		font-size: 29px;
	}

	.fs-md-30px
	{
		font-size: 30px;
	}

	.fs-md-31px
	{
		font-size: 31px;
	}

	.fs-md-32px
	{
		font-size: 32px;
	}

	.fs-md-33px
	{
		font-size: 33px;
	}

	.fs-md-34px
	{
		font-size: 34px;
	}

	.fs-md-35px
	{
		font-size: 35px;
	}

	.fs-md-36px
	{
		font-size: 36px;
	}

	.fs-md-37px
	{
		font-size: 37px;
	}

	.fs-md-38px
	{
		font-size: 38px;
	}

	.fs-md-39px
	{
		font-size: 39px;
	}

	.fs-md-40px
	{
		font-size: 40px;
	}

	.fs-md-41px
	{
		font-size: 41px;
	}

	.fs-md-42px
	{
		font-size: 42px;
	}

	.fs-md-43px
	{
		font-size: 43px;
	}

	.fs-md-44px
	{
		font-size: 44px;
	}

	.fs-md-45px
	{
		font-size: 45px;
	}

	.fs-md-46px
	{
		font-size: 46px;
	}

	.fs-md-47px
	{
		font-size: 47px;
	}

	.fs-md-48px
	{
		font-size: 48px;
	}

	.fs-md-49px
	{
		font-size: 49px;
	}

	.fs-md-50px
	{
		font-size: 50px;
	}

	.fs-md-51px
	{
		font-size: 51px;
	}

	.fs-md-52px
	{
		font-size: 52px;
	}

	.fs-md-53px
	{
		font-size: 53px;
	}

	.fs-md-54px
	{
		font-size: 54px;
	}

	.fs-md-55px
	{
		font-size: 55px;
	}

	.fs-md-56px
	{
		font-size: 56px;
	}

	.fs-md-57px
	{
		font-size: 57px;
	}

	.fs-md-58px
	{
		font-size: 58px;
	}

	.fs-md-59px
	{
		font-size: 59px;
	}

	.fs-md-60px
	{
		font-size: 60px;
	}

	.fs-md-61px
	{
		font-size: 61px;
	}

	.fs-md-62px
	{
		font-size: 62px;
	}

	.fs-md-63px
	{
		font-size: 63px;
	}

	.fs-md-64px
	{
		font-size: 64px;
	}

	.fs-md-65px
	{
		font-size: 65px;
	}

	.fs-md-66px
	{
		font-size: 66px;
	}

	.fs-md-67px
	{
		font-size: 67px;
	}

	.fs-md-68px
	{
		font-size: 68px;
	}

	.fs-md-69px
	{
		font-size: 69px;
	}

	.fs-md-70px
	{
		font-size: 70px;
	}

	.fs-md-71px
	{
		font-size: 71px;
	}

	.fs-md-72px
	{
		font-size: 72px;
	}

	.fs-md-73px
	{
		font-size: 73px;
	}

	.fs-md-74px
	{
		font-size: 74px;
	}

	.fs-md-75px
	{
		font-size: 75px;
	}

	.fs-md-76px
	{
		font-size: 76px;
	}

	.fs-md-77px
	{
		font-size: 77px;
	}

	.fs-md-78px
	{
		font-size: 78px;
	}

	.fs-md-79px
	{
		font-size: 79px;
	}

	.fs-md-80px
	{
		font-size: 80px;
	}

	.fs-md-81px
	{
		font-size: 81px;
	}

	.fs-md-82px
	{
		font-size: 82px;
	}

	.fs-md-83px
	{
		font-size: 83px;
	}

	.fs-md-84px
	{
		font-size: 84px;
	}

	.fs-md-85px
	{
		font-size: 85px;
	}

	.fs-md-86px
	{
		font-size: 86px;
	}

	.fs-md-87px
	{
		font-size: 87px;
	}

	.fs-md-88px
	{
		font-size: 88px;
	}

	.fs-md-89px
	{
		font-size: 89px;
	}

	.fs-md-90px
	{
		font-size: 90px;
	}

	.fs-md-91px
	{
		font-size: 91px;
	}

	.fs-md-92px
	{
		font-size: 92px;
	}

	.fs-md-93px
	{
		font-size: 93px;
	}

	.fs-md-94px
	{
		font-size: 94px;
	}

	.fs-md-95px
	{
		font-size: 95px;
	}

	.fs-md-96px
	{
		font-size: 96px;
	}

	.fs-md-97px
	{
		font-size: 97px;
	}

	.fs-md-98px
	{
		font-size: 98px;
	}

	.fs-md-99px
	{
		font-size: 99px;
	}

	.fs-md-100px
	{
		font-size: 100px;
	}

	.fs-md-101px
	{
		font-size: 101px;
	}

	.fs-md-102px
	{
		font-size: 102px;
	}

	.fs-md-103px
	{
		font-size: 103px;
	}

	.fs-md-104px
	{
		font-size: 104px;
	}

	.fs-md-105px
	{
		font-size: 105px;
	}

	.fs-md-106px
	{
		font-size: 106px;
	}

	.fs-md-107px
	{
		font-size: 107px;
	}

	.fs-md-108px
	{
		font-size: 108px;
	}

	.fs-md-109px
	{
		font-size: 109px;
	}

	.fs-md-110px
	{
		font-size: 110px;
	}

	.fs-md-111px
	{
		font-size: 111px;
	}

	.fs-md-112px
	{
		font-size: 112px;
	}

	.fs-md-113px
	{
		font-size: 113px;
	}

	.fs-md-114px
	{
		font-size: 114px;
	}

	.fs-md-115px
	{
		font-size: 115px;
	}

	.fs-md-116px
	{
		font-size: 116px;
	}

	.fs-md-117px
	{
		font-size: 117px;
	}

	.fs-md-118px
	{
		font-size: 118px;
	}

	.fs-md-119px
	{
		font-size: 119px;
	}

	.fs-md-120px
	{
		font-size: 120px;
	}

	.fs-md-121px
	{
		font-size: 121px;
	}

	.fs-md-122px
	{
		font-size: 122px;
	}

	.fs-md-123px
	{
		font-size: 123px;
	}

	.fs-md-124px
	{
		font-size: 124px;
	}

	.fs-md-125px
	{
		font-size: 125px;
	}

	.fs-md-126px
	{
		font-size: 126px;
	}

	.fs-md-127px
	{
		font-size: 127px;
	}

	.fs-md-128px
	{
		font-size: 128px;
	}

	.fs-md-129px
	{
		font-size: 129px;
	}

	.fs-md-130px
	{
		font-size: 130px;
	}

	.fs-md-131px
	{
		font-size: 131px;
	}

	.fs-md-132px
	{
		font-size: 132px;
	}

	.fs-md-133px
	{
		font-size: 133px;
	}

	.fs-md-134px
	{
		font-size: 134px;
	}

	.fs-md-135px
	{
		font-size: 135px;
	}

	.fs-md-136px
	{
		font-size: 136px;
	}

	.fs-md-137px
	{
		font-size: 137px;
	}

	.fs-md-138px
	{
		font-size: 138px;
	}

	.fs-md-139px
	{
		font-size: 139px;
	}

	.fs-md-140px
	{
		font-size: 140px;
	}

	.fs-md-141px
	{
		font-size: 141px;
	}

	.fs-md-142px
	{
		font-size: 142px;
	}

	.fs-md-143px
	{
		font-size: 143px;
	}

	.fs-md-144px
	{
		font-size: 144px;
	}

	.fs-md-145px
	{
		font-size: 145px;
	}

	.fs-md-146px
	{
		font-size: 146px;
	}

	.fs-md-147px
	{
		font-size: 147px;
	}

	.fs-md-148px
	{
		font-size: 148px;
	}

	.fs-md-149px
	{
		font-size: 149px;
	}

	.fs-md-150px
	{
		font-size: 150px;
	}

	.fs-md-151px
	{
		font-size: 151px;
	}

	.fs-md-152px
	{
		font-size: 152px;
	}

	.fs-md-153px
	{
		font-size: 153px;
	}

	.fs-md-154px
	{
		font-size: 154px;
	}

	.fs-md-155px
	{
		font-size: 155px;
	}

	.fs-md-156px
	{
		font-size: 156px;
	}

	.fs-md-157px
	{
		font-size: 157px;
	}

	.fs-md-158px
	{
		font-size: 158px;
	}

	.fs-md-159px
	{
		font-size: 159px;
	}

	.fs-md-160px
	{
		font-size: 160px;
	}

	.fs-md-161px
	{
		font-size: 161px;
	}

	.fs-md-162px
	{
		font-size: 162px;
	}

	.fs-md-163px
	{
		font-size: 163px;
	}

	.fs-md-164px
	{
		font-size: 164px;
	}

	.fs-md-165px
	{
		font-size: 165px;
	}

	.fs-md-166px
	{
		font-size: 166px;
	}

	.fs-md-167px
	{
		font-size: 167px;
	}

	.fs-md-168px
	{
		font-size: 168px;
	}

	.fs-md-169px
	{
		font-size: 169px;
	}

	.fs-md-170px
	{
		font-size: 170px;
	}

	.fs-md-171px
	{
		font-size: 171px;
	}

	.fs-md-172px
	{
		font-size: 172px;
	}

	.fs-md-173px
	{
		font-size: 173px;
	}

	.fs-md-174px
	{
		font-size: 174px;
	}

	.fs-md-175px
	{
		font-size: 175px;
	}

	.fs-md-176px
	{
		font-size: 176px;
	}

	.fs-md-177px
	{
		font-size: 177px;
	}

	.fs-md-178px
	{
		font-size: 178px;
	}

	.fs-md-179px
	{
		font-size: 179px;
	}

	.fs-md-180px
	{
		font-size: 180px;
	}

	.fs-md-181px
	{
		font-size: 181px;
	}

	.fs-md-182px
	{
		font-size: 182px;
	}

	.fs-md-183px
	{
		font-size: 183px;
	}

	.fs-md-184px
	{
		font-size: 184px;
	}

	.fs-md-185px
	{
		font-size: 185px;
	}

	.fs-md-186px
	{
		font-size: 186px;
	}

	.fs-md-187px
	{
		font-size: 187px;
	}

	.fs-md-188px
	{
		font-size: 188px;
	}

	.fs-md-189px
	{
		font-size: 189px;
	}

	.fs-md-190px
	{
		font-size: 190px;
	}

	.fs-md-191px
	{
		font-size: 191px;
	}

	.fs-md-192px
	{
		font-size: 192px;
	}

	.fs-md-193px
	{
		font-size: 193px;
	}

	.fs-md-194px
	{
		font-size: 194px;
	}

	.fs-md-195px
	{
		font-size: 195px;
	}

	.fs-md-196px
	{
		font-size: 196px;
	}

	.fs-md-197px
	{
		font-size: 197px;
	}

	.fs-md-198px
	{
		font-size: 198px;
	}

	.fs-md-199px
	{
		font-size: 199px;
	}

	.fs-md-200px
	{
		font-size: 200px;
	}

	.fs-md-201px
	{
		font-size: 201px;
	}

	.fs-md-202px
	{
		font-size: 202px;
	}

	.fs-md-203px
	{
		font-size: 203px;
	}

	.fs-md-204px
	{
		font-size: 204px;
	}

	.fs-md-205px
	{
		font-size: 205px;
	}

	.fs-md-206px
	{
		font-size: 206px;
	}

	.fs-md-207px
	{
		font-size: 207px;
	}

	.fs-md-208px
	{
		font-size: 208px;
	}

	.fs-md-209px
	{
		font-size: 209px;
	}

	.fs-md-210px
	{
		font-size: 210px;
	}

	.fs-md-211px
	{
		font-size: 211px;
	}

	.fs-md-212px
	{
		font-size: 212px;
	}

	.fs-md-213px
	{
		font-size: 213px;
	}

	.fs-md-214px
	{
		font-size: 214px;
	}

	.fs-md-215px
	{
		font-size: 215px;
	}

	.fs-md-216px
	{
		font-size: 216px;
	}

	.fs-md-217px
	{
		font-size: 217px;
	}

	.fs-md-218px
	{
		font-size: 218px;
	}

	.fs-md-219px
	{
		font-size: 219px;
	}

	.fs-md-220px
	{
		font-size: 220px;
	}

	.fs-md-221px
	{
		font-size: 221px;
	}

	.fs-md-222px
	{
		font-size: 222px;
	}

	.fs-md-223px
	{
		font-size: 223px;
	}

	.fs-md-224px
	{
		font-size: 224px;
	}

	.fs-md-225px
	{
		font-size: 225px;
	}

	.fs-md-226px
	{
		font-size: 226px;
	}

	.fs-md-227px
	{
		font-size: 227px;
	}

	.fs-md-228px
	{
		font-size: 228px;
	}

	.fs-md-229px
	{
		font-size: 229px;
	}

	.fs-md-230px
	{
		font-size: 230px;
	}

	.fs-md-231px
	{
		font-size: 231px;
	}

	.fs-md-232px
	{
		font-size: 232px;
	}

	.fs-md-233px
	{
		font-size: 233px;
	}

	.fs-md-234px
	{
		font-size: 234px;
	}

	.fs-md-235px
	{
		font-size: 235px;
	}

	.fs-md-236px
	{
		font-size: 236px;
	}

	.fs-md-237px
	{
		font-size: 237px;
	}

	.fs-md-238px
	{
		font-size: 238px;
	}

	.fs-md-239px
	{
		font-size: 239px;
	}

	.fs-md-240px
	{
		font-size: 240px;
	}

	.fs-md-241px
	{
		font-size: 241px;
	}

	.fs-md-242px
	{
		font-size: 242px;
	}

	.fs-md-243px
	{
		font-size: 243px;
	}

	.fs-md-244px
	{
		font-size: 244px;
	}

	.fs-md-245px
	{
		font-size: 245px;
	}

	.fs-md-246px
	{
		font-size: 246px;
	}

	.fs-md-247px
	{
		font-size: 247px;
	}

	.fs-md-248px
	{
		font-size: 248px;
	}

	.fs-md-249px
	{
		font-size: 249px;
	}

	.fs-md-250px
	{
		font-size: 250px;
	}

	.fs-md-251px
	{
		font-size: 251px;
	}

	.fs-md-252px
	{
		font-size: 252px;
	}

	.fs-md-253px
	{
		font-size: 253px;
	}

	.fs-md-254px
	{
		font-size: 254px;
	}

	.fs-md-255px
	{
		font-size: 255px;
	}

	.fs-md-256px
	{
		font-size: 256px;
	}

}

@media (min-width: 992px)
{	
	.fs-lg-1px
	{
		font-size: 1px;
	}

	.fs-lg-2px
	{
		font-size: 2px;
	}

	.fs-lg-3px
	{
		font-size: 3px;
	}

	.fs-lg-4px
	{
		font-size: 4px;
	}

	.fs-lg-5px
	{
		font-size: 5px;
	}

	.fs-lg-6px
	{
		font-size: 6px;
	}

	.fs-lg-7px
	{
		font-size: 7px;
	}

	.fs-lg-8px
	{
		font-size: 8px;
	}

	.fs-lg-9px
	{
		font-size: 9px;
	}

	.fs-lg-10px
	{
		font-size: 10px;
	}

	.fs-lg-11px
	{
		font-size: 11px;
	}

	.fs-lg-12px
	{
		font-size: 12px;
	}

	.fs-lg-13px
	{
		font-size: 13px;
	}

	.fs-lg-14px
	{
		font-size: 14px;
	}

	.fs-lg-15px
	{
		font-size: 15px;
	}

	.fs-lg-16px
	{
		font-size: 16px;
	}

	.fs-lg-17px
	{
		font-size: 17px;
	}

	.fs-lg-18px
	{
		font-size: 18px;
	}

	.fs-lg-19px
	{
		font-size: 19px;
	}

	.fs-lg-20px
	{
		font-size: 20px;
	}

	.fs-lg-21px
	{
		font-size: 21px;
	}

	.fs-lg-22px
	{
		font-size: 22px;
	}

	.fs-lg-23px
	{
		font-size: 23px;
	}

	.fs-lg-24px
	{
		font-size: 24px;
	}

	.fs-lg-25px
	{
		font-size: 25px;
	}

	.fs-lg-26px
	{
		font-size: 26px;
	}

	.fs-lg-27px
	{
		font-size: 27px;
	}

	.fs-lg-28px
	{
		font-size: 28px;
	}

	.fs-lg-29px
	{
		font-size: 29px;
	}

	.fs-lg-30px
	{
		font-size: 30px;
	}

	.fs-lg-31px
	{
		font-size: 31px;
	}

	.fs-lg-32px
	{
		font-size: 32px;
	}

	.fs-lg-33px
	{
		font-size: 33px;
	}

	.fs-lg-34px
	{
		font-size: 34px;
	}

	.fs-lg-35px
	{
		font-size: 35px;
	}

	.fs-lg-36px
	{
		font-size: 36px;
	}

	.fs-lg-37px
	{
		font-size: 37px;
	}

	.fs-lg-38px
	{
		font-size: 38px;
	}

	.fs-lg-39px
	{
		font-size: 39px;
	}

	.fs-lg-40px
	{
		font-size: 40px;
	}

	.fs-lg-41px
	{
		font-size: 41px;
	}

	.fs-lg-42px
	{
		font-size: 42px;
	}

	.fs-lg-43px
	{
		font-size: 43px;
	}

	.fs-lg-44px
	{
		font-size: 44px;
	}

	.fs-lg-45px
	{
		font-size: 45px;
	}

	.fs-lg-46px
	{
		font-size: 46px;
	}

	.fs-lg-47px
	{
		font-size: 47px;
	}

	.fs-lg-48px
	{
		font-size: 48px;
	}

	.fs-lg-49px
	{
		font-size: 49px;
	}

	.fs-lg-50px
	{
		font-size: 50px;
	}

	.fs-lg-51px
	{
		font-size: 51px;
	}

	.fs-lg-52px
	{
		font-size: 52px;
	}

	.fs-lg-53px
	{
		font-size: 53px;
	}

	.fs-lg-54px
	{
		font-size: 54px;
	}

	.fs-lg-55px
	{
		font-size: 55px;
	}

	.fs-lg-56px
	{
		font-size: 56px;
	}

	.fs-lg-57px
	{
		font-size: 57px;
	}

	.fs-lg-58px
	{
		font-size: 58px;
	}

	.fs-lg-59px
	{
		font-size: 59px;
	}

	.fs-lg-60px
	{
		font-size: 60px;
	}

	.fs-lg-61px
	{
		font-size: 61px;
	}

	.fs-lg-62px
	{
		font-size: 62px;
	}

	.fs-lg-63px
	{
		font-size: 63px;
	}

	.fs-lg-64px
	{
		font-size: 64px;
	}

	.fs-lg-65px
	{
		font-size: 65px;
	}

	.fs-lg-66px
	{
		font-size: 66px;
	}

	.fs-lg-67px
	{
		font-size: 67px;
	}

	.fs-lg-68px
	{
		font-size: 68px;
	}

	.fs-lg-69px
	{
		font-size: 69px;
	}

	.fs-lg-70px
	{
		font-size: 70px;
	}

	.fs-lg-71px
	{
		font-size: 71px;
	}

	.fs-lg-72px
	{
		font-size: 72px;
	}

	.fs-lg-73px
	{
		font-size: 73px;
	}

	.fs-lg-74px
	{
		font-size: 74px;
	}

	.fs-lg-75px
	{
		font-size: 75px;
	}

	.fs-lg-76px
	{
		font-size: 76px;
	}

	.fs-lg-77px
	{
		font-size: 77px;
	}

	.fs-lg-78px
	{
		font-size: 78px;
	}

	.fs-lg-79px
	{
		font-size: 79px;
	}

	.fs-lg-80px
	{
		font-size: 80px;
	}

	.fs-lg-81px
	{
		font-size: 81px;
	}

	.fs-lg-82px
	{
		font-size: 82px;
	}

	.fs-lg-83px
	{
		font-size: 83px;
	}

	.fs-lg-84px
	{
		font-size: 84px;
	}

	.fs-lg-85px
	{
		font-size: 85px;
	}

	.fs-lg-86px
	{
		font-size: 86px;
	}

	.fs-lg-87px
	{
		font-size: 87px;
	}

	.fs-lg-88px
	{
		font-size: 88px;
	}

	.fs-lg-89px
	{
		font-size: 89px;
	}

	.fs-lg-90px
	{
		font-size: 90px;
	}

	.fs-lg-91px
	{
		font-size: 91px;
	}

	.fs-lg-92px
	{
		font-size: 92px;
	}

	.fs-lg-93px
	{
		font-size: 93px;
	}

	.fs-lg-94px
	{
		font-size: 94px;
	}

	.fs-lg-95px
	{
		font-size: 95px;
	}

	.fs-lg-96px
	{
		font-size: 96px;
	}

	.fs-lg-97px
	{
		font-size: 97px;
	}

	.fs-lg-98px
	{
		font-size: 98px;
	}

	.fs-lg-99px
	{
		font-size: 99px;
	}

	.fs-lg-100px
	{
		font-size: 100px;
	}

	.fs-lg-101px
	{
		font-size: 101px;
	}

	.fs-lg-102px
	{
		font-size: 102px;
	}

	.fs-lg-103px
	{
		font-size: 103px;
	}

	.fs-lg-104px
	{
		font-size: 104px;
	}

	.fs-lg-105px
	{
		font-size: 105px;
	}

	.fs-lg-106px
	{
		font-size: 106px;
	}

	.fs-lg-107px
	{
		font-size: 107px;
	}

	.fs-lg-108px
	{
		font-size: 108px;
	}

	.fs-lg-109px
	{
		font-size: 109px;
	}

	.fs-lg-110px
	{
		font-size: 110px;
	}

	.fs-lg-111px
	{
		font-size: 111px;
	}

	.fs-lg-112px
	{
		font-size: 112px;
	}

	.fs-lg-113px
	{
		font-size: 113px;
	}

	.fs-lg-114px
	{
		font-size: 114px;
	}

	.fs-lg-115px
	{
		font-size: 115px;
	}

	.fs-lg-116px
	{
		font-size: 116px;
	}

	.fs-lg-117px
	{
		font-size: 117px;
	}

	.fs-lg-118px
	{
		font-size: 118px;
	}

	.fs-lg-119px
	{
		font-size: 119px;
	}

	.fs-lg-120px
	{
		font-size: 120px;
	}

	.fs-lg-121px
	{
		font-size: 121px;
	}

	.fs-lg-122px
	{
		font-size: 122px;
	}

	.fs-lg-123px
	{
		font-size: 123px;
	}

	.fs-lg-124px
	{
		font-size: 124px;
	}

	.fs-lg-125px
	{
		font-size: 125px;
	}

	.fs-lg-126px
	{
		font-size: 126px;
	}

	.fs-lg-127px
	{
		font-size: 127px;
	}

	.fs-lg-128px
	{
		font-size: 128px;
	}

	.fs-lg-129px
	{
		font-size: 129px;
	}

	.fs-lg-130px
	{
		font-size: 130px;
	}

	.fs-lg-131px
	{
		font-size: 131px;
	}

	.fs-lg-132px
	{
		font-size: 132px;
	}

	.fs-lg-133px
	{
		font-size: 133px;
	}

	.fs-lg-134px
	{
		font-size: 134px;
	}

	.fs-lg-135px
	{
		font-size: 135px;
	}

	.fs-lg-136px
	{
		font-size: 136px;
	}

	.fs-lg-137px
	{
		font-size: 137px;
	}

	.fs-lg-138px
	{
		font-size: 138px;
	}

	.fs-lg-139px
	{
		font-size: 139px;
	}

	.fs-lg-140px
	{
		font-size: 140px;
	}

	.fs-lg-141px
	{
		font-size: 141px;
	}

	.fs-lg-142px
	{
		font-size: 142px;
	}

	.fs-lg-143px
	{
		font-size: 143px;
	}

	.fs-lg-144px
	{
		font-size: 144px;
	}

	.fs-lg-145px
	{
		font-size: 145px;
	}

	.fs-lg-146px
	{
		font-size: 146px;
	}

	.fs-lg-147px
	{
		font-size: 147px;
	}

	.fs-lg-148px
	{
		font-size: 148px;
	}

	.fs-lg-149px
	{
		font-size: 149px;
	}

	.fs-lg-150px
	{
		font-size: 150px;
	}

	.fs-lg-151px
	{
		font-size: 151px;
	}

	.fs-lg-152px
	{
		font-size: 152px;
	}

	.fs-lg-153px
	{
		font-size: 153px;
	}

	.fs-lg-154px
	{
		font-size: 154px;
	}

	.fs-lg-155px
	{
		font-size: 155px;
	}

	.fs-lg-156px
	{
		font-size: 156px;
	}

	.fs-lg-157px
	{
		font-size: 157px;
	}

	.fs-lg-158px
	{
		font-size: 158px;
	}

	.fs-lg-159px
	{
		font-size: 159px;
	}

	.fs-lg-160px
	{
		font-size: 160px;
	}

	.fs-lg-161px
	{
		font-size: 161px;
	}

	.fs-lg-162px
	{
		font-size: 162px;
	}

	.fs-lg-163px
	{
		font-size: 163px;
	}

	.fs-lg-164px
	{
		font-size: 164px;
	}

	.fs-lg-165px
	{
		font-size: 165px;
	}

	.fs-lg-166px
	{
		font-size: 166px;
	}

	.fs-lg-167px
	{
		font-size: 167px;
	}

	.fs-lg-168px
	{
		font-size: 168px;
	}

	.fs-lg-169px
	{
		font-size: 169px;
	}

	.fs-lg-170px
	{
		font-size: 170px;
	}

	.fs-lg-171px
	{
		font-size: 171px;
	}

	.fs-lg-172px
	{
		font-size: 172px;
	}

	.fs-lg-173px
	{
		font-size: 173px;
	}

	.fs-lg-174px
	{
		font-size: 174px;
	}

	.fs-lg-175px
	{
		font-size: 175px;
	}

	.fs-lg-176px
	{
		font-size: 176px;
	}

	.fs-lg-177px
	{
		font-size: 177px;
	}

	.fs-lg-178px
	{
		font-size: 178px;
	}

	.fs-lg-179px
	{
		font-size: 179px;
	}

	.fs-lg-180px
	{
		font-size: 180px;
	}

	.fs-lg-181px
	{
		font-size: 181px;
	}

	.fs-lg-182px
	{
		font-size: 182px;
	}

	.fs-lg-183px
	{
		font-size: 183px;
	}

	.fs-lg-184px
	{
		font-size: 184px;
	}

	.fs-lg-185px
	{
		font-size: 185px;
	}

	.fs-lg-186px
	{
		font-size: 186px;
	}

	.fs-lg-187px
	{
		font-size: 187px;
	}

	.fs-lg-188px
	{
		font-size: 188px;
	}

	.fs-lg-189px
	{
		font-size: 189px;
	}

	.fs-lg-190px
	{
		font-size: 190px;
	}

	.fs-lg-191px
	{
		font-size: 191px;
	}

	.fs-lg-192px
	{
		font-size: 192px;
	}

	.fs-lg-193px
	{
		font-size: 193px;
	}

	.fs-lg-194px
	{
		font-size: 194px;
	}

	.fs-lg-195px
	{
		font-size: 195px;
	}

	.fs-lg-196px
	{
		font-size: 196px;
	}

	.fs-lg-197px
	{
		font-size: 197px;
	}

	.fs-lg-198px
	{
		font-size: 198px;
	}

	.fs-lg-199px
	{
		font-size: 199px;
	}

	.fs-lg-200px
	{
		font-size: 200px;
	}

	.fs-lg-201px
	{
		font-size: 201px;
	}

	.fs-lg-202px
	{
		font-size: 202px;
	}

	.fs-lg-203px
	{
		font-size: 203px;
	}

	.fs-lg-204px
	{
		font-size: 204px;
	}

	.fs-lg-205px
	{
		font-size: 205px;
	}

	.fs-lg-206px
	{
		font-size: 206px;
	}

	.fs-lg-207px
	{
		font-size: 207px;
	}

	.fs-lg-208px
	{
		font-size: 208px;
	}

	.fs-lg-209px
	{
		font-size: 209px;
	}

	.fs-lg-210px
	{
		font-size: 210px;
	}

	.fs-lg-211px
	{
		font-size: 211px;
	}

	.fs-lg-212px
	{
		font-size: 212px;
	}

	.fs-lg-213px
	{
		font-size: 213px;
	}

	.fs-lg-214px
	{
		font-size: 214px;
	}

	.fs-lg-215px
	{
		font-size: 215px;
	}

	.fs-lg-216px
	{
		font-size: 216px;
	}

	.fs-lg-217px
	{
		font-size: 217px;
	}

	.fs-lg-218px
	{
		font-size: 218px;
	}

	.fs-lg-219px
	{
		font-size: 219px;
	}

	.fs-lg-220px
	{
		font-size: 220px;
	}

	.fs-lg-221px
	{
		font-size: 221px;
	}

	.fs-lg-222px
	{
		font-size: 222px;
	}

	.fs-lg-223px
	{
		font-size: 223px;
	}

	.fs-lg-224px
	{
		font-size: 224px;
	}

	.fs-lg-225px
	{
		font-size: 225px;
	}

	.fs-lg-226px
	{
		font-size: 226px;
	}

	.fs-lg-227px
	{
		font-size: 227px;
	}

	.fs-lg-228px
	{
		font-size: 228px;
	}

	.fs-lg-229px
	{
		font-size: 229px;
	}

	.fs-lg-230px
	{
		font-size: 230px;
	}

	.fs-lg-231px
	{
		font-size: 231px;
	}

	.fs-lg-232px
	{
		font-size: 232px;
	}

	.fs-lg-233px
	{
		font-size: 233px;
	}

	.fs-lg-234px
	{
		font-size: 234px;
	}

	.fs-lg-235px
	{
		font-size: 235px;
	}

	.fs-lg-236px
	{
		font-size: 236px;
	}

	.fs-lg-237px
	{
		font-size: 237px;
	}

	.fs-lg-238px
	{
		font-size: 238px;
	}

	.fs-lg-239px
	{
		font-size: 239px;
	}

	.fs-lg-240px
	{
		font-size: 240px;
	}

	.fs-lg-241px
	{
		font-size: 241px;
	}

	.fs-lg-242px
	{
		font-size: 242px;
	}

	.fs-lg-243px
	{
		font-size: 243px;
	}

	.fs-lg-244px
	{
		font-size: 244px;
	}

	.fs-lg-245px
	{
		font-size: 245px;
	}

	.fs-lg-246px
	{
		font-size: 246px;
	}

	.fs-lg-247px
	{
		font-size: 247px;
	}

	.fs-lg-248px
	{
		font-size: 248px;
	}

	.fs-lg-249px
	{
		font-size: 249px;
	}

	.fs-lg-250px
	{
		font-size: 250px;
	}

	.fs-lg-251px
	{
		font-size: 251px;
	}

	.fs-lg-252px
	{
		font-size: 252px;
	}

	.fs-lg-253px
	{
		font-size: 253px;
	}

	.fs-lg-254px
	{
		font-size: 254px;
	}

	.fs-lg-255px
	{
		font-size: 255px;
	}

	.fs-lg-256px
	{
		font-size: 256px;
	}

}

@media (min-width: 1200px)
{	
	.fs-xl-1px
	{
		font-size: 1px;
	}

	.fs-xl-2px
	{
		font-size: 2px;
	}

	.fs-xl-3px
	{
		font-size: 3px;
	}

	.fs-xl-4px
	{
		font-size: 4px;
	}

	.fs-xl-5px
	{
		font-size: 5px;
	}

	.fs-xl-6px
	{
		font-size: 6px;
	}

	.fs-xl-7px
	{
		font-size: 7px;
	}

	.fs-xl-8px
	{
		font-size: 8px;
	}

	.fs-xl-9px
	{
		font-size: 9px;
	}

	.fs-xl-10px
	{
		font-size: 10px;
	}

	.fs-xl-11px
	{
		font-size: 11px;
	}

	.fs-xl-12px
	{
		font-size: 12px;
	}

	.fs-xl-13px
	{
		font-size: 13px;
	}

	.fs-xl-14px
	{
		font-size: 14px;
	}

	.fs-xl-15px
	{
		font-size: 15px;
	}

	.fs-xl-16px
	{
		font-size: 16px;
	}

	.fs-xl-17px
	{
		font-size: 17px;
	}

	.fs-xl-18px
	{
		font-size: 18px;
	}

	.fs-xl-19px
	{
		font-size: 19px;
	}

	.fs-xl-20px
	{
		font-size: 20px;
	}

	.fs-xl-21px
	{
		font-size: 21px;
	}

	.fs-xl-22px
	{
		font-size: 22px;
	}

	.fs-xl-23px
	{
		font-size: 23px;
	}

	.fs-xl-24px
	{
		font-size: 24px;
	}

	.fs-xl-25px
	{
		font-size: 25px;
	}

	.fs-xl-26px
	{
		font-size: 26px;
	}

	.fs-xl-27px
	{
		font-size: 27px;
	}

	.fs-xl-28px
	{
		font-size: 28px;
	}

	.fs-xl-29px
	{
		font-size: 29px;
	}

	.fs-xl-30px
	{
		font-size: 30px;
	}

	.fs-xl-31px
	{
		font-size: 31px;
	}

	.fs-xl-32px
	{
		font-size: 32px;
	}

	.fs-xl-33px
	{
		font-size: 33px;
	}

	.fs-xl-34px
	{
		font-size: 34px;
	}

	.fs-xl-35px
	{
		font-size: 35px;
	}

	.fs-xl-36px
	{
		font-size: 36px;
	}

	.fs-xl-37px
	{
		font-size: 37px;
	}

	.fs-xl-38px
	{
		font-size: 38px;
	}

	.fs-xl-39px
	{
		font-size: 39px;
	}

	.fs-xl-40px
	{
		font-size: 40px;
	}

	.fs-xl-41px
	{
		font-size: 41px;
	}

	.fs-xl-42px
	{
		font-size: 42px;
	}

	.fs-xl-43px
	{
		font-size: 43px;
	}

	.fs-xl-44px
	{
		font-size: 44px;
	}

	.fs-xl-45px
	{
		font-size: 45px;
	}

	.fs-xl-46px
	{
		font-size: 46px;
	}

	.fs-xl-47px
	{
		font-size: 47px;
	}

	.fs-xl-48px
	{
		font-size: 48px;
	}

	.fs-xl-49px
	{
		font-size: 49px;
	}

	.fs-xl-50px
	{
		font-size: 50px;
	}

	.fs-xl-51px
	{
		font-size: 51px;
	}

	.fs-xl-52px
	{
		font-size: 52px;
	}

	.fs-xl-53px
	{
		font-size: 53px;
	}

	.fs-xl-54px
	{
		font-size: 54px;
	}

	.fs-xl-55px
	{
		font-size: 55px;
	}

	.fs-xl-56px
	{
		font-size: 56px;
	}

	.fs-xl-57px
	{
		font-size: 57px;
	}

	.fs-xl-58px
	{
		font-size: 58px;
	}

	.fs-xl-59px
	{
		font-size: 59px;
	}

	.fs-xl-60px
	{
		font-size: 60px;
	}

	.fs-xl-61px
	{
		font-size: 61px;
	}

	.fs-xl-62px
	{
		font-size: 62px;
	}

	.fs-xl-63px
	{
		font-size: 63px;
	}

	.fs-xl-64px
	{
		font-size: 64px;
	}

	.fs-xl-65px
	{
		font-size: 65px;
	}

	.fs-xl-66px
	{
		font-size: 66px;
	}

	.fs-xl-67px
	{
		font-size: 67px;
	}

	.fs-xl-68px
	{
		font-size: 68px;
	}

	.fs-xl-69px
	{
		font-size: 69px;
	}

	.fs-xl-70px
	{
		font-size: 70px;
	}

	.fs-xl-71px
	{
		font-size: 71px;
	}

	.fs-xl-72px
	{
		font-size: 72px;
	}

	.fs-xl-73px
	{
		font-size: 73px;
	}

	.fs-xl-74px
	{
		font-size: 74px;
	}

	.fs-xl-75px
	{
		font-size: 75px;
	}

	.fs-xl-76px
	{
		font-size: 76px;
	}

	.fs-xl-77px
	{
		font-size: 77px;
	}

	.fs-xl-78px
	{
		font-size: 78px;
	}

	.fs-xl-79px
	{
		font-size: 79px;
	}

	.fs-xl-80px
	{
		font-size: 80px;
	}

	.fs-xl-81px
	{
		font-size: 81px;
	}

	.fs-xl-82px
	{
		font-size: 82px;
	}

	.fs-xl-83px
	{
		font-size: 83px;
	}

	.fs-xl-84px
	{
		font-size: 84px;
	}

	.fs-xl-85px
	{
		font-size: 85px;
	}

	.fs-xl-86px
	{
		font-size: 86px;
	}

	.fs-xl-87px
	{
		font-size: 87px;
	}

	.fs-xl-88px
	{
		font-size: 88px;
	}

	.fs-xl-89px
	{
		font-size: 89px;
	}

	.fs-xl-90px
	{
		font-size: 90px;
	}

	.fs-xl-91px
	{
		font-size: 91px;
	}

	.fs-xl-92px
	{
		font-size: 92px;
	}

	.fs-xl-93px
	{
		font-size: 93px;
	}

	.fs-xl-94px
	{
		font-size: 94px;
	}

	.fs-xl-95px
	{
		font-size: 95px;
	}

	.fs-xl-96px
	{
		font-size: 96px;
	}

	.fs-xl-97px
	{
		font-size: 97px;
	}

	.fs-xl-98px
	{
		font-size: 98px;
	}

	.fs-xl-99px
	{
		font-size: 99px;
	}

	.fs-xl-100px
	{
		font-size: 100px;
	}

	.fs-xl-101px
	{
		font-size: 101px;
	}

	.fs-xl-102px
	{
		font-size: 102px;
	}

	.fs-xl-103px
	{
		font-size: 103px;
	}

	.fs-xl-104px
	{
		font-size: 104px;
	}

	.fs-xl-105px
	{
		font-size: 105px;
	}

	.fs-xl-106px
	{
		font-size: 106px;
	}

	.fs-xl-107px
	{
		font-size: 107px;
	}

	.fs-xl-108px
	{
		font-size: 108px;
	}

	.fs-xl-109px
	{
		font-size: 109px;
	}

	.fs-xl-110px
	{
		font-size: 110px;
	}

	.fs-xl-111px
	{
		font-size: 111px;
	}

	.fs-xl-112px
	{
		font-size: 112px;
	}

	.fs-xl-113px
	{
		font-size: 113px;
	}

	.fs-xl-114px
	{
		font-size: 114px;
	}

	.fs-xl-115px
	{
		font-size: 115px;
	}

	.fs-xl-116px
	{
		font-size: 116px;
	}

	.fs-xl-117px
	{
		font-size: 117px;
	}

	.fs-xl-118px
	{
		font-size: 118px;
	}

	.fs-xl-119px
	{
		font-size: 119px;
	}

	.fs-xl-120px
	{
		font-size: 120px;
	}

	.fs-xl-121px
	{
		font-size: 121px;
	}

	.fs-xl-122px
	{
		font-size: 122px;
	}

	.fs-xl-123px
	{
		font-size: 123px;
	}

	.fs-xl-124px
	{
		font-size: 124px;
	}

	.fs-xl-125px
	{
		font-size: 125px;
	}

	.fs-xl-126px
	{
		font-size: 126px;
	}

	.fs-xl-127px
	{
		font-size: 127px;
	}

	.fs-xl-128px
	{
		font-size: 128px;
	}

	.fs-xl-129px
	{
		font-size: 129px;
	}

	.fs-xl-130px
	{
		font-size: 130px;
	}

	.fs-xl-131px
	{
		font-size: 131px;
	}

	.fs-xl-132px
	{
		font-size: 132px;
	}

	.fs-xl-133px
	{
		font-size: 133px;
	}

	.fs-xl-134px
	{
		font-size: 134px;
	}

	.fs-xl-135px
	{
		font-size: 135px;
	}

	.fs-xl-136px
	{
		font-size: 136px;
	}

	.fs-xl-137px
	{
		font-size: 137px;
	}

	.fs-xl-138px
	{
		font-size: 138px;
	}

	.fs-xl-139px
	{
		font-size: 139px;
	}

	.fs-xl-140px
	{
		font-size: 140px;
	}

	.fs-xl-141px
	{
		font-size: 141px;
	}

	.fs-xl-142px
	{
		font-size: 142px;
	}

	.fs-xl-143px
	{
		font-size: 143px;
	}

	.fs-xl-144px
	{
		font-size: 144px;
	}

	.fs-xl-145px
	{
		font-size: 145px;
	}

	.fs-xl-146px
	{
		font-size: 146px;
	}

	.fs-xl-147px
	{
		font-size: 147px;
	}

	.fs-xl-148px
	{
		font-size: 148px;
	}

	.fs-xl-149px
	{
		font-size: 149px;
	}

	.fs-xl-150px
	{
		font-size: 150px;
	}

	.fs-xl-151px
	{
		font-size: 151px;
	}

	.fs-xl-152px
	{
		font-size: 152px;
	}

	.fs-xl-153px
	{
		font-size: 153px;
	}

	.fs-xl-154px
	{
		font-size: 154px;
	}

	.fs-xl-155px
	{
		font-size: 155px;
	}

	.fs-xl-156px
	{
		font-size: 156px;
	}

	.fs-xl-157px
	{
		font-size: 157px;
	}

	.fs-xl-158px
	{
		font-size: 158px;
	}

	.fs-xl-159px
	{
		font-size: 159px;
	}

	.fs-xl-160px
	{
		font-size: 160px;
	}

	.fs-xl-161px
	{
		font-size: 161px;
	}

	.fs-xl-162px
	{
		font-size: 162px;
	}

	.fs-xl-163px
	{
		font-size: 163px;
	}

	.fs-xl-164px
	{
		font-size: 164px;
	}

	.fs-xl-165px
	{
		font-size: 165px;
	}

	.fs-xl-166px
	{
		font-size: 166px;
	}

	.fs-xl-167px
	{
		font-size: 167px;
	}

	.fs-xl-168px
	{
		font-size: 168px;
	}

	.fs-xl-169px
	{
		font-size: 169px;
	}

	.fs-xl-170px
	{
		font-size: 170px;
	}

	.fs-xl-171px
	{
		font-size: 171px;
	}

	.fs-xl-172px
	{
		font-size: 172px;
	}

	.fs-xl-173px
	{
		font-size: 173px;
	}

	.fs-xl-174px
	{
		font-size: 174px;
	}

	.fs-xl-175px
	{
		font-size: 175px;
	}

	.fs-xl-176px
	{
		font-size: 176px;
	}

	.fs-xl-177px
	{
		font-size: 177px;
	}

	.fs-xl-178px
	{
		font-size: 178px;
	}

	.fs-xl-179px
	{
		font-size: 179px;
	}

	.fs-xl-180px
	{
		font-size: 180px;
	}

	.fs-xl-181px
	{
		font-size: 181px;
	}

	.fs-xl-182px
	{
		font-size: 182px;
	}

	.fs-xl-183px
	{
		font-size: 183px;
	}

	.fs-xl-184px
	{
		font-size: 184px;
	}

	.fs-xl-185px
	{
		font-size: 185px;
	}

	.fs-xl-186px
	{
		font-size: 186px;
	}

	.fs-xl-187px
	{
		font-size: 187px;
	}

	.fs-xl-188px
	{
		font-size: 188px;
	}

	.fs-xl-189px
	{
		font-size: 189px;
	}

	.fs-xl-190px
	{
		font-size: 190px;
	}

	.fs-xl-191px
	{
		font-size: 191px;
	}

	.fs-xl-192px
	{
		font-size: 192px;
	}

	.fs-xl-193px
	{
		font-size: 193px;
	}

	.fs-xl-194px
	{
		font-size: 194px;
	}

	.fs-xl-195px
	{
		font-size: 195px;
	}

	.fs-xl-196px
	{
		font-size: 196px;
	}

	.fs-xl-197px
	{
		font-size: 197px;
	}

	.fs-xl-198px
	{
		font-size: 198px;
	}

	.fs-xl-199px
	{
		font-size: 199px;
	}

	.fs-xl-200px
	{
		font-size: 200px;
	}

	.fs-xl-201px
	{
		font-size: 201px;
	}

	.fs-xl-202px
	{
		font-size: 202px;
	}

	.fs-xl-203px
	{
		font-size: 203px;
	}

	.fs-xl-204px
	{
		font-size: 204px;
	}

	.fs-xl-205px
	{
		font-size: 205px;
	}

	.fs-xl-206px
	{
		font-size: 206px;
	}

	.fs-xl-207px
	{
		font-size: 207px;
	}

	.fs-xl-208px
	{
		font-size: 208px;
	}

	.fs-xl-209px
	{
		font-size: 209px;
	}

	.fs-xl-210px
	{
		font-size: 210px;
	}

	.fs-xl-211px
	{
		font-size: 211px;
	}

	.fs-xl-212px
	{
		font-size: 212px;
	}

	.fs-xl-213px
	{
		font-size: 213px;
	}

	.fs-xl-214px
	{
		font-size: 214px;
	}

	.fs-xl-215px
	{
		font-size: 215px;
	}

	.fs-xl-216px
	{
		font-size: 216px;
	}

	.fs-xl-217px
	{
		font-size: 217px;
	}

	.fs-xl-218px
	{
		font-size: 218px;
	}

	.fs-xl-219px
	{
		font-size: 219px;
	}

	.fs-xl-220px
	{
		font-size: 220px;
	}

	.fs-xl-221px
	{
		font-size: 221px;
	}

	.fs-xl-222px
	{
		font-size: 222px;
	}

	.fs-xl-223px
	{
		font-size: 223px;
	}

	.fs-xl-224px
	{
		font-size: 224px;
	}

	.fs-xl-225px
	{
		font-size: 225px;
	}

	.fs-xl-226px
	{
		font-size: 226px;
	}

	.fs-xl-227px
	{
		font-size: 227px;
	}

	.fs-xl-228px
	{
		font-size: 228px;
	}

	.fs-xl-229px
	{
		font-size: 229px;
	}

	.fs-xl-230px
	{
		font-size: 230px;
	}

	.fs-xl-231px
	{
		font-size: 231px;
	}

	.fs-xl-232px
	{
		font-size: 232px;
	}

	.fs-xl-233px
	{
		font-size: 233px;
	}

	.fs-xl-234px
	{
		font-size: 234px;
	}

	.fs-xl-235px
	{
		font-size: 235px;
	}

	.fs-xl-236px
	{
		font-size: 236px;
	}

	.fs-xl-237px
	{
		font-size: 237px;
	}

	.fs-xl-238px
	{
		font-size: 238px;
	}

	.fs-xl-239px
	{
		font-size: 239px;
	}

	.fs-xl-240px
	{
		font-size: 240px;
	}

	.fs-xl-241px
	{
		font-size: 241px;
	}

	.fs-xl-242px
	{
		font-size: 242px;
	}

	.fs-xl-243px
	{
		font-size: 243px;
	}

	.fs-xl-244px
	{
		font-size: 244px;
	}

	.fs-xl-245px
	{
		font-size: 245px;
	}

	.fs-xl-246px
	{
		font-size: 246px;
	}

	.fs-xl-247px
	{
		font-size: 247px;
	}

	.fs-xl-248px
	{
		font-size: 248px;
	}

	.fs-xl-249px
	{
		font-size: 249px;
	}

	.fs-xl-250px
	{
		font-size: 250px;
	}

	.fs-xl-251px
	{
		font-size: 251px;
	}

	.fs-xl-252px
	{
		font-size: 252px;
	}

	.fs-xl-253px
	{
		font-size: 253px;
	}

	.fs-xl-254px
	{
		font-size: 254px;
	}

	.fs-xl-255px
	{
		font-size: 255px;
	}

	.fs-xl-256px
	{
		font-size: 256px;
	}

}

@media (min-width: 1400px)
{	
	.fs-xxl-1px
	{
		font-size: 1px;
	}

	.fs-xxl-2px
	{
		font-size: 2px;
	}

	.fs-xxl-3px
	{
		font-size: 3px;
	}

	.fs-xxl-4px
	{
		font-size: 4px;
	}

	.fs-xxl-5px
	{
		font-size: 5px;
	}

	.fs-xxl-6px
	{
		font-size: 6px;
	}

	.fs-xxl-7px
	{
		font-size: 7px;
	}

	.fs-xxl-8px
	{
		font-size: 8px;
	}

	.fs-xxl-9px
	{
		font-size: 9px;
	}

	.fs-xxl-10px
	{
		font-size: 10px;
	}

	.fs-xxl-11px
	{
		font-size: 11px;
	}

	.fs-xxl-12px
	{
		font-size: 12px;
	}

	.fs-xxl-13px
	{
		font-size: 13px;
	}

	.fs-xxl-14px
	{
		font-size: 14px;
	}

	.fs-xxl-15px
	{
		font-size: 15px;
	}

	.fs-xxl-16px
	{
		font-size: 16px;
	}

	.fs-xxl-17px
	{
		font-size: 17px;
	}

	.fs-xxl-18px
	{
		font-size: 18px;
	}

	.fs-xxl-19px
	{
		font-size: 19px;
	}

	.fs-xxl-20px
	{
		font-size: 20px;
	}

	.fs-xxl-21px
	{
		font-size: 21px;
	}

	.fs-xxl-22px
	{
		font-size: 22px;
	}

	.fs-xxl-23px
	{
		font-size: 23px;
	}

	.fs-xxl-24px
	{
		font-size: 24px;
	}

	.fs-xxl-25px
	{
		font-size: 25px;
	}

	.fs-xxl-26px
	{
		font-size: 26px;
	}

	.fs-xxl-27px
	{
		font-size: 27px;
	}

	.fs-xxl-28px
	{
		font-size: 28px;
	}

	.fs-xxl-29px
	{
		font-size: 29px;
	}

	.fs-xxl-30px
	{
		font-size: 30px;
	}

	.fs-xxl-31px
	{
		font-size: 31px;
	}

	.fs-xxl-32px
	{
		font-size: 32px;
	}

	.fs-xxl-33px
	{
		font-size: 33px;
	}

	.fs-xxl-34px
	{
		font-size: 34px;
	}

	.fs-xxl-35px
	{
		font-size: 35px;
	}

	.fs-xxl-36px
	{
		font-size: 36px;
	}

	.fs-xxl-37px
	{
		font-size: 37px;
	}

	.fs-xxl-38px
	{
		font-size: 38px;
	}

	.fs-xxl-39px
	{
		font-size: 39px;
	}

	.fs-xxl-40px
	{
		font-size: 40px;
	}

	.fs-xxl-41px
	{
		font-size: 41px;
	}

	.fs-xxl-42px
	{
		font-size: 42px;
	}

	.fs-xxl-43px
	{
		font-size: 43px;
	}

	.fs-xxl-44px
	{
		font-size: 44px;
	}

	.fs-xxl-45px
	{
		font-size: 45px;
	}

	.fs-xxl-46px
	{
		font-size: 46px;
	}

	.fs-xxl-47px
	{
		font-size: 47px;
	}

	.fs-xxl-48px
	{
		font-size: 48px;
	}

	.fs-xxl-49px
	{
		font-size: 49px;
	}

	.fs-xxl-50px
	{
		font-size: 50px;
	}

	.fs-xxl-51px
	{
		font-size: 51px;
	}

	.fs-xxl-52px
	{
		font-size: 52px;
	}

	.fs-xxl-53px
	{
		font-size: 53px;
	}

	.fs-xxl-54px
	{
		font-size: 54px;
	}

	.fs-xxl-55px
	{
		font-size: 55px;
	}

	.fs-xxl-56px
	{
		font-size: 56px;
	}

	.fs-xxl-57px
	{
		font-size: 57px;
	}

	.fs-xxl-58px
	{
		font-size: 58px;
	}

	.fs-xxl-59px
	{
		font-size: 59px;
	}

	.fs-xxl-60px
	{
		font-size: 60px;
	}

	.fs-xxl-61px
	{
		font-size: 61px;
	}

	.fs-xxl-62px
	{
		font-size: 62px;
	}

	.fs-xxl-63px
	{
		font-size: 63px;
	}

	.fs-xxl-64px
	{
		font-size: 64px;
	}

	.fs-xxl-65px
	{
		font-size: 65px;
	}

	.fs-xxl-66px
	{
		font-size: 66px;
	}

	.fs-xxl-67px
	{
		font-size: 67px;
	}

	.fs-xxl-68px
	{
		font-size: 68px;
	}

	.fs-xxl-69px
	{
		font-size: 69px;
	}

	.fs-xxl-70px
	{
		font-size: 70px;
	}

	.fs-xxl-71px
	{
		font-size: 71px;
	}

	.fs-xxl-72px
	{
		font-size: 72px;
	}

	.fs-xxl-73px
	{
		font-size: 73px;
	}

	.fs-xxl-74px
	{
		font-size: 74px;
	}

	.fs-xxl-75px
	{
		font-size: 75px;
	}

	.fs-xxl-76px
	{
		font-size: 76px;
	}

	.fs-xxl-77px
	{
		font-size: 77px;
	}

	.fs-xxl-78px
	{
		font-size: 78px;
	}

	.fs-xxl-79px
	{
		font-size: 79px;
	}

	.fs-xxl-80px
	{
		font-size: 80px;
	}

	.fs-xxl-81px
	{
		font-size: 81px;
	}

	.fs-xxl-82px
	{
		font-size: 82px;
	}

	.fs-xxl-83px
	{
		font-size: 83px;
	}

	.fs-xxl-84px
	{
		font-size: 84px;
	}

	.fs-xxl-85px
	{
		font-size: 85px;
	}

	.fs-xxl-86px
	{
		font-size: 86px;
	}

	.fs-xxl-87px
	{
		font-size: 87px;
	}

	.fs-xxl-88px
	{
		font-size: 88px;
	}

	.fs-xxl-89px
	{
		font-size: 89px;
	}

	.fs-xxl-90px
	{
		font-size: 90px;
	}

	.fs-xxl-91px
	{
		font-size: 91px;
	}

	.fs-xxl-92px
	{
		font-size: 92px;
	}

	.fs-xxl-93px
	{
		font-size: 93px;
	}

	.fs-xxl-94px
	{
		font-size: 94px;
	}

	.fs-xxl-95px
	{
		font-size: 95px;
	}

	.fs-xxl-96px
	{
		font-size: 96px;
	}

	.fs-xxl-97px
	{
		font-size: 97px;
	}

	.fs-xxl-98px
	{
		font-size: 98px;
	}

	.fs-xxl-99px
	{
		font-size: 99px;
	}

	.fs-xxl-100px
	{
		font-size: 100px;
	}

	.fs-xxl-101px
	{
		font-size: 101px;
	}

	.fs-xxl-102px
	{
		font-size: 102px;
	}

	.fs-xxl-103px
	{
		font-size: 103px;
	}

	.fs-xxl-104px
	{
		font-size: 104px;
	}

	.fs-xxl-105px
	{
		font-size: 105px;
	}

	.fs-xxl-106px
	{
		font-size: 106px;
	}

	.fs-xxl-107px
	{
		font-size: 107px;
	}

	.fs-xxl-108px
	{
		font-size: 108px;
	}

	.fs-xxl-109px
	{
		font-size: 109px;
	}

	.fs-xxl-110px
	{
		font-size: 110px;
	}

	.fs-xxl-111px
	{
		font-size: 111px;
	}

	.fs-xxl-112px
	{
		font-size: 112px;
	}

	.fs-xxl-113px
	{
		font-size: 113px;
	}

	.fs-xxl-114px
	{
		font-size: 114px;
	}

	.fs-xxl-115px
	{
		font-size: 115px;
	}

	.fs-xxl-116px
	{
		font-size: 116px;
	}

	.fs-xxl-117px
	{
		font-size: 117px;
	}

	.fs-xxl-118px
	{
		font-size: 118px;
	}

	.fs-xxl-119px
	{
		font-size: 119px;
	}

	.fs-xxl-120px
	{
		font-size: 120px;
	}

	.fs-xxl-121px
	{
		font-size: 121px;
	}

	.fs-xxl-122px
	{
		font-size: 122px;
	}

	.fs-xxl-123px
	{
		font-size: 123px;
	}

	.fs-xxl-124px
	{
		font-size: 124px;
	}

	.fs-xxl-125px
	{
		font-size: 125px;
	}

	.fs-xxl-126px
	{
		font-size: 126px;
	}

	.fs-xxl-127px
	{
		font-size: 127px;
	}

	.fs-xxl-128px
	{
		font-size: 128px;
	}

	.fs-xxl-129px
	{
		font-size: 129px;
	}

	.fs-xxl-130px
	{
		font-size: 130px;
	}

	.fs-xxl-131px
	{
		font-size: 131px;
	}

	.fs-xxl-132px
	{
		font-size: 132px;
	}

	.fs-xxl-133px
	{
		font-size: 133px;
	}

	.fs-xxl-134px
	{
		font-size: 134px;
	}

	.fs-xxl-135px
	{
		font-size: 135px;
	}

	.fs-xxl-136px
	{
		font-size: 136px;
	}

	.fs-xxl-137px
	{
		font-size: 137px;
	}

	.fs-xxl-138px
	{
		font-size: 138px;
	}

	.fs-xxl-139px
	{
		font-size: 139px;
	}

	.fs-xxl-140px
	{
		font-size: 140px;
	}

	.fs-xxl-141px
	{
		font-size: 141px;
	}

	.fs-xxl-142px
	{
		font-size: 142px;
	}

	.fs-xxl-143px
	{
		font-size: 143px;
	}

	.fs-xxl-144px
	{
		font-size: 144px;
	}

	.fs-xxl-145px
	{
		font-size: 145px;
	}

	.fs-xxl-146px
	{
		font-size: 146px;
	}

	.fs-xxl-147px
	{
		font-size: 147px;
	}

	.fs-xxl-148px
	{
		font-size: 148px;
	}

	.fs-xxl-149px
	{
		font-size: 149px;
	}

	.fs-xxl-150px
	{
		font-size: 150px;
	}

	.fs-xxl-151px
	{
		font-size: 151px;
	}

	.fs-xxl-152px
	{
		font-size: 152px;
	}

	.fs-xxl-153px
	{
		font-size: 153px;
	}

	.fs-xxl-154px
	{
		font-size: 154px;
	}

	.fs-xxl-155px
	{
		font-size: 155px;
	}

	.fs-xxl-156px
	{
		font-size: 156px;
	}

	.fs-xxl-157px
	{
		font-size: 157px;
	}

	.fs-xxl-158px
	{
		font-size: 158px;
	}

	.fs-xxl-159px
	{
		font-size: 159px;
	}

	.fs-xxl-160px
	{
		font-size: 160px;
	}

	.fs-xxl-161px
	{
		font-size: 161px;
	}

	.fs-xxl-162px
	{
		font-size: 162px;
	}

	.fs-xxl-163px
	{
		font-size: 163px;
	}

	.fs-xxl-164px
	{
		font-size: 164px;
	}

	.fs-xxl-165px
	{
		font-size: 165px;
	}

	.fs-xxl-166px
	{
		font-size: 166px;
	}

	.fs-xxl-167px
	{
		font-size: 167px;
	}

	.fs-xxl-168px
	{
		font-size: 168px;
	}

	.fs-xxl-169px
	{
		font-size: 169px;
	}

	.fs-xxl-170px
	{
		font-size: 170px;
	}

	.fs-xxl-171px
	{
		font-size: 171px;
	}

	.fs-xxl-172px
	{
		font-size: 172px;
	}

	.fs-xxl-173px
	{
		font-size: 173px;
	}

	.fs-xxl-174px
	{
		font-size: 174px;
	}

	.fs-xxl-175px
	{
		font-size: 175px;
	}

	.fs-xxl-176px
	{
		font-size: 176px;
	}

	.fs-xxl-177px
	{
		font-size: 177px;
	}

	.fs-xxl-178px
	{
		font-size: 178px;
	}

	.fs-xxl-179px
	{
		font-size: 179px;
	}

	.fs-xxl-180px
	{
		font-size: 180px;
	}

	.fs-xxl-181px
	{
		font-size: 181px;
	}

	.fs-xxl-182px
	{
		font-size: 182px;
	}

	.fs-xxl-183px
	{
		font-size: 183px;
	}

	.fs-xxl-184px
	{
		font-size: 184px;
	}

	.fs-xxl-185px
	{
		font-size: 185px;
	}

	.fs-xxl-186px
	{
		font-size: 186px;
	}

	.fs-xxl-187px
	{
		font-size: 187px;
	}

	.fs-xxl-188px
	{
		font-size: 188px;
	}

	.fs-xxl-189px
	{
		font-size: 189px;
	}

	.fs-xxl-190px
	{
		font-size: 190px;
	}

	.fs-xxl-191px
	{
		font-size: 191px;
	}

	.fs-xxl-192px
	{
		font-size: 192px;
	}

	.fs-xxl-193px
	{
		font-size: 193px;
	}

	.fs-xxl-194px
	{
		font-size: 194px;
	}

	.fs-xxl-195px
	{
		font-size: 195px;
	}

	.fs-xxl-196px
	{
		font-size: 196px;
	}

	.fs-xxl-197px
	{
		font-size: 197px;
	}

	.fs-xxl-198px
	{
		font-size: 198px;
	}

	.fs-xxl-199px
	{
		font-size: 199px;
	}

	.fs-xxl-200px
	{
		font-size: 200px;
	}

	.fs-xxl-201px
	{
		font-size: 201px;
	}

	.fs-xxl-202px
	{
		font-size: 202px;
	}

	.fs-xxl-203px
	{
		font-size: 203px;
	}

	.fs-xxl-204px
	{
		font-size: 204px;
	}

	.fs-xxl-205px
	{
		font-size: 205px;
	}

	.fs-xxl-206px
	{
		font-size: 206px;
	}

	.fs-xxl-207px
	{
		font-size: 207px;
	}

	.fs-xxl-208px
	{
		font-size: 208px;
	}

	.fs-xxl-209px
	{
		font-size: 209px;
	}

	.fs-xxl-210px
	{
		font-size: 210px;
	}

	.fs-xxl-211px
	{
		font-size: 211px;
	}

	.fs-xxl-212px
	{
		font-size: 212px;
	}

	.fs-xxl-213px
	{
		font-size: 213px;
	}

	.fs-xxl-214px
	{
		font-size: 214px;
	}

	.fs-xxl-215px
	{
		font-size: 215px;
	}

	.fs-xxl-216px
	{
		font-size: 216px;
	}

	.fs-xxl-217px
	{
		font-size: 217px;
	}

	.fs-xxl-218px
	{
		font-size: 218px;
	}

	.fs-xxl-219px
	{
		font-size: 219px;
	}

	.fs-xxl-220px
	{
		font-size: 220px;
	}

	.fs-xxl-221px
	{
		font-size: 221px;
	}

	.fs-xxl-222px
	{
		font-size: 222px;
	}

	.fs-xxl-223px
	{
		font-size: 223px;
	}

	.fs-xxl-224px
	{
		font-size: 224px;
	}

	.fs-xxl-225px
	{
		font-size: 225px;
	}

	.fs-xxl-226px
	{
		font-size: 226px;
	}

	.fs-xxl-227px
	{
		font-size: 227px;
	}

	.fs-xxl-228px
	{
		font-size: 228px;
	}

	.fs-xxl-229px
	{
		font-size: 229px;
	}

	.fs-xxl-230px
	{
		font-size: 230px;
	}

	.fs-xxl-231px
	{
		font-size: 231px;
	}

	.fs-xxl-232px
	{
		font-size: 232px;
	}

	.fs-xxl-233px
	{
		font-size: 233px;
	}

	.fs-xxl-234px
	{
		font-size: 234px;
	}

	.fs-xxl-235px
	{
		font-size: 235px;
	}

	.fs-xxl-236px
	{
		font-size: 236px;
	}

	.fs-xxl-237px
	{
		font-size: 237px;
	}

	.fs-xxl-238px
	{
		font-size: 238px;
	}

	.fs-xxl-239px
	{
		font-size: 239px;
	}

	.fs-xxl-240px
	{
		font-size: 240px;
	}

	.fs-xxl-241px
	{
		font-size: 241px;
	}

	.fs-xxl-242px
	{
		font-size: 242px;
	}

	.fs-xxl-243px
	{
		font-size: 243px;
	}

	.fs-xxl-244px
	{
		font-size: 244px;
	}

	.fs-xxl-245px
	{
		font-size: 245px;
	}

	.fs-xxl-246px
	{
		font-size: 246px;
	}

	.fs-xxl-247px
	{
		font-size: 247px;
	}

	.fs-xxl-248px
	{
		font-size: 248px;
	}

	.fs-xxl-249px
	{
		font-size: 249px;
	}

	.fs-xxl-250px
	{
		font-size: 250px;
	}

	.fs-xxl-251px
	{
		font-size: 251px;
	}

	.fs-xxl-252px
	{
		font-size: 252px;
	}

	.fs-xxl-253px
	{
		font-size: 253px;
	}

	.fs-xxl-254px
	{
		font-size: 254px;
	}

	.fs-xxl-255px
	{
		font-size: 255px;
	}

	.fs-xxl-256px
	{
		font-size: 256px;
	}

}

